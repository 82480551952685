import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import { Restore } from "@material-ui/icons";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { Unpublished } from "@mui/icons-material";
import { Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	DiscreteHeader,
	Header,
} from "../../../../components/Typography/Typography";
import { getModelById } from "../../../../services/api/models";
import {
	finishOrder,
	getOrder,
	reactivateOrder,
} from "../../../../services/api/orders";
import { ICarModelDto } from "../../../../types/CarModel.types";
import { IOrderDto } from "../../../../types/Order.types";
import OrderPartsDetails from "./Tabs/Parts/OrderPartsDetails";
import OrderServiceDetails from "./Tabs/Services/OrderServiceDetails";

export default function OrderDetails() {
	let { id } = useParams<{ id: string }>();
	const [order, setOrder] = useState<IOrderDto>();
	const [refresh, setRefresh] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>();
	const [value, setValue] = useState("1");
	const [modelData, setModelData] = useState<ICarModelDto>();
	const [modelDescription, setModelDescription] = useState<string>("");

	const handleChange = (event: any, newValue: string) => {
		setValue(newValue);
	};

	const orderData = useCallback(async () => {
		try {
			const data = await getOrder(id!);
			setOrder(data as IOrderDto);
		} catch (error: any) {
			console.error(error);
		} finally {
		}
	}, [id]);

	const model = useCallback(async () => {
		try {
			const data = await getModelById(order?.linkedIds[0]!);
			setModelData(data as ICarModelDto);
			setModelDescription(
				`${modelData?.manufacturerName} ${
					modelData?.name.split(" ")[0]
				} ${modelData?.engineCapacity} ${
					(modelData?.cilinderAmount ?? 0) *
					(modelData?.valvesPerCilinder ?? 0)
				}V ${modelData?.fuel} ${modelData?.transmission} ${
					order?.year ?? ""
				}` ?? ""
			);
		} catch (error: any) {
			console.error(error);
		} finally {
		}
	}, [
		modelData?.cilinderAmount,
		modelData?.engineCapacity,
		modelData?.fuel,
		modelData?.manufacturerName,
		modelData?.name,
		modelData?.transmission,
		modelData?.valvesPerCilinder,
		order?.linkedIds,
		order?.year,
	]);

	useEffect(() => {
		orderData();
		setIsActive(order?.isActive);
	}, [order?.isActive, orderData, refresh]);

	useEffect(() => {
		model();
	}, [model]);

	const handleOrderStateChange = async () => {
		try {
			isActive ? await finishOrder(id!) : await reactivateOrder(id!);
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
				spacing={1}
			>
				<Grid item xs={8}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link underline="hover" color="inherit" href="/home">
							Orçamentos
						</Link>
						<Typography color="primary">{id}</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>

			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<Grid item>
					<DiscreteHeader
						text={`Criado em ${order?.createdDate!.toLocaleDateString(
							"pt-BR",
							{
								timeZone: "America/Sao_Paulo",
							}
						)}`}
					/>
				</Grid>

				<Grid container justifyContent="flex-end" item xs={2}>
					<Button
						size="small"
						variant="contained"
						endIcon={isActive ? <Unpublished /> : <Restore />}
						style={{
							backgroundColor: isActive ? "#f44336" : "#44A274",
							color: "white",
						}}
						onClick={() => handleOrderStateChange()}
					>
						{isActive ? "Inativar" : "Reativar"}
					</Button>
				</Grid>
			</Grid>

			<br />

			<Grid
				justifyContent="space-between"
				alignItems="flex-end"
				container
			>
				<Header text={order?.plate.toUpperCase() ?? ""} />
			</Grid>
			<Grid
				justifyContent="space-between"
				alignItems="flex-end"
				container
			>
				<Header text={modelDescription} />
			</Grid>

			<TabContext value={value}>
				<TabList
					style={{ color: "#ff7221" }}
					onChange={handleChange}
					aria-label="lab API tabs example"
					indicatorColor="primary"
				>
					<Tab label="SERVIÇOS" value="1" />
					<Tab label="ORÇAMENTOS DE PEÇAS" value="2" />
				</TabList>
				<TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="1"
				>
					<OrderServiceDetails />
				</TabPanel>
				<TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="2"
				>
					<OrderPartsDetails
						modelDescription={modelDescription}
						modelPlate={order?.plate.toUpperCase() ?? ""}
					/>
				</TabPanel>
			</TabContext>
		</>
	);
}
