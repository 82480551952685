import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";

interface IModalProps {
	title?: string;
	description?: string;
	isOpen: boolean;
	handleSave?: any;
	handleCancel: any;
	children?: JSX.Element;
	formToSubmitId?: string;
	wide?: boolean;
	actionText?: string;
}

export default function FormModal(props: IModalProps) {
	return (
		<>
			<Dialog
				maxWidth={props.wide ? "lg" : "sm"}
				open={props.isOpen}
				onClose={props.handleCancel}
				scroll="paper"
				fullWidth
				aria-labelledby="form-dialog-title"
				style={{ minHeight: "100vh", maxHeight: "100vh" }}
			>
				<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{props.description}</DialogContentText>
					{props.children}
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleCancel} color="primary">
						Cancelar
					</Button>
					<Button
						type="submit"
						form={props.formToSubmitId ?? "form"}
						color="primary"
					>
						{props.actionText ?? "Salvar"}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
