import axios from "axios";
import Endpoints from "../../Endpoints";
import {
	IAnosModelo,
	IFipeMarca,
	IFipeTabelaDeReferencia,
	IManufactuturerModelsResponse,
	ITodosValores,
} from "../../types/Fipe.types";

const fipeClient = axios.create({
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
});

async function getTabelaReferencia(): Promise<IFipeTabelaDeReferencia> {
	try {
		const res = await fipeClient.post(Endpoints.fipe.getTabelaReferencia);
		const refererTable = res.data[0];
		return refererTable as IFipeTabelaDeReferencia;
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar referencias");
	}
}

async function getManufacturers(): Promise<IFipeMarca[]> {
	try {
		const tabelaReferencia = await getTabelaReferencia();

		const payload = {
			codigoTabelaReferencia: tabelaReferencia.Codigo,
			codigoTipoVeiculo: 1,
		};

		const res = await fipeClient.post(Endpoints.fipe.getMarcas, payload);

		let manufacturers: IFipeMarca[] = res.data.map((m: IFipeMarca) => {
			if (m.Label.toLowerCase().includes("volkswagen")) {
				return {
					Label: "Volkswagen",
					Value: "Volkswagen",
				} as IFipeMarca;
			}

			if (m.Label.toLowerCase().includes("chevrolet")) {
				return {
					Label: "Chevrolet",
					Value: "Chevrolet",
				} as IFipeMarca;
			}

			return m as IFipeMarca;
		});

		return manufacturers;
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar marcas");
	}
}

async function getManufacturerModels(
	codigoMarca: number
): Promise<IManufactuturerModelsResponse> {
	try {
		const tabelaReferencia = await getTabelaReferencia();

		const payload = {
			codigoTabelaReferencia: tabelaReferencia.Codigo,
			codigoTipoVeiculo: 1,
			codigoMarca: codigoMarca,
		};

		const res = await fipeClient.post(
			Endpoints.fipe.getModelosPorMarca,
			payload
		);
		return res.data as IManufactuturerModelsResponse;
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar modelos");
	}
}

async function getModelYears(codigoMarca: string, codigoModelo: string) {
	try {
		const tabelaReferencia = await getTabelaReferencia();

		const payload = {
			codigoTabelaReferencia: tabelaReferencia.Codigo,
			codigoTipoVeiculo: 1,
			codigoMarca: codigoMarca,
			codigoModelo: codigoModelo,
		};

		const res = await fipeClient.post(
			Endpoints.fipe.getAnosPorModelo,
			payload
		);

		return res.data as IAnosModelo[];
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar anos do modelo");
	}
}

async function getDataByModelYear(
	codigoMarca: string,
	codigoModelo: string,
	year: string
) {
	try {
		const tabelaReferencia = await getTabelaReferencia();

		const payload = {
			codigoTabelaReferencia: tabelaReferencia.Codigo,
			codigoTipoVeiculo: 1,
			codigoMarca: codigoMarca,
			codigoModelo: codigoModelo,
			ano: year,
			codigoTipoCombustivel: 1,
			anoModelo: parseInt(year.split("-")[0]),
			tipoConsulta: "tradicional",
		};

		const res = await fipeClient.post(
			Endpoints.fipe.getValorComTodosParametros,
			payload
		);

		return res.data as ITodosValores;
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar dados");
	}
}

export {
	getDataByModelYear,
	getManufacturerModels,
	getManufacturers,
	getModelYears,
	getTabelaReferencia,
};
