import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionButtons } from "../../../../../../components/GridActions";
import FormModal from "../../../../../../components/Modals/FormModal";
import { removeGroupService } from "../../../../../../services/api/models";
import {
	IGroupServiceDto,
	IServiceDto,
} from "../../../../../../types/Services.types";
import { parseDecimalToHoursAndMinutes } from "../../../../../../utils/helpers/parsers";
import GroupDetails from "./GroupDetails/GroupDetails";

interface IGroupServiceGridData
	extends Omit<IServiceDto, "description" | "system"> {
	percentage: number;
}

interface IGroupGridData {
	name: string;
	services: IGroupServiceGridData[];
	duration: number;
}

interface IGroupsGridProps {
	id: string;
	refresh: boolean;
	groups: IGroupServiceDto[];
	refreshParent: boolean;
	refreshParentHandler: any;
	services: IServiceDto[];
	adjustmentFactor?: number;
}

function GroupsGrid(props: IGroupsGridProps) {
	const [data, setData] = useState<IGroupGridData[]>([]);
	const [group, setGroup] = useState<IGroupServiceDto>();
	const [openModal, setOpenModal] = useState<boolean>(false);

	useEffect(() => {
		const groups = props.groups.map((group) => {
			const services = group.services.map((service) => {
				const serviceDto = props.services.find(
					(x) => x.id === service.id
				)!;
				return {
					...service,
					duration: serviceDto.duration * service.percentage,
				};
			});
			return {
				...group,
				services,
				duration: services.reduce(
					(acc, curr) => acc + curr.duration,
					0
				),
			};
		});

		setData(groups);
	}, [props.groups, props.services]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	function onEditGroup(name: string) {
		const group = data.find((group) => group.name === name);
		setGroup(group);
		setOpenModal(true);
	}

	async function onDelete(id: string, name: string) {
		// eslint-disable-next-line no-restricted-globals
		const mustDelete: boolean = confirm(
			"Você tem certeza disso? Esta ação não poderá ser desfeita..."
		);
		if (mustDelete) {
			try {
				await removeGroupService(id, name);
				props.refreshParentHandler(!props.refreshParent);
			} catch (error: any) {
				console.error(error);
			}
		} else {
			alert("A ação foi cancelada.");
		}
	}

	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Nome do Grupo",
			flex: 1,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "duration",
			headerName: "Duração",
			headerClassName: "super-app-theme--header",
			align: "center",
			headerAlign: "center",
			flex: 0.3,
			renderCell: (obj) => (
				<>
					<p>{parseDecimalToHoursAndMinutes(obj.row.duration)}</p>
				</>
			),
		},
		{
			field: " ",
			headerName: "Ações",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			renderCell: (obj) => (
				<>
					<ActionButtons
						onDelete={() => onDelete(props.id, obj.row.name)}
						onEdit={() => onEditGroup(obj.row.name)}
					/>
				</>
			),
			flex: 0.7,
			align: "center",
		},
	];

	return (
		<>
			<FormModal
				title="Detalhes do Grupo de Serviço"
				wide
				handleCancel={handleCancel}
				isOpen={openModal}
				formToSubmitId="groupDetailsForm"
			>
				<GroupDetails
					modelId={props.id}
					group={group!}
					modelServices={props.services}
					refreshParentHandler={props.refreshParentHandler}
					refreshParent={props.refreshParent}
					closeModal={setOpenModal}
					adjustmentFactor={props.adjustmentFactor}
				/>
			</FormModal>
			<Box
				sx={{
					height: 300,
					width: "100%",
					"& .super-app-theme--header": {
						backgroundColor: "#ff7221",
					},
				}}
			>
				<DataGrid
					disableSelectionOnClick
					density="compact"
					rows={data}
					getRowId={(row: any) => row.name.replace(" ", "")}
					columns={columns}
					pageSize={10}
					components={{
						NoRowsOverlay: () => (
							<>
								<Box
									sx={{
										mt: 1,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "flex-end",
										height: "20%",
									}}
								>
									Não há serviços para este modelo
								</Box>
							</>
						),
					}}
				/>
			</Box>
		</>
	);
}
export { GroupsGrid };
