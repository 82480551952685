import {
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import { IOrderDto, IProceedingDto } from "../../types/Order.types";
import { IOrderPartsDto, IPartEstimateDto } from "../../types/Parts.types";
import { IOrderServiceDto, IServiceDto } from "../../types/Services.types";
import {
	calculatePartEstimateProfitFactor,
	updateItemInArrayById,
} from "../../utils/helpers/parsers";
import { db, ordersRef } from "../firestore";
import { getServiceDataById } from "./services";
import { getTemplateById } from "./templates";

// READ SECTION
async function getOrders(isActive: boolean): Promise<IOrderDto[]> {
	try {
		var q = query(ordersRef, where("isActive", "==", isActive));
		var dataSnapshot = await getDocs(q);

		let orders = dataSnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				externalId: data.externalId,
				model: data.model,
				isActive: data.isActive,
				totalAmount: data.totalAmount,
				manufacturer: data.manufacturer,
				createdDate: data.createdDate.toDate(),
				linkedIds: data.linkedIds,
				plate: data.plate,
				year: data.year,
				serviceGroups: data.serviceGroups,
				services: data.services,
				parts: data.parts,
				fipeCode: data.fipeCode,
				type: data.type,
				proceedings: data.proceedings,
				modelAdjustmentFactor: data.modelAdjustmentFactor,
			} as IOrderDto;
		});
		return orders;
	} catch (error: any) {
		console.error(error);
	}
	return [];
}

async function getOrderByExternalId(externalId: string): Promise<IOrderDto[]> {
	try {
		var q = query(ordersRef, where("externalId", "==", externalId));
		var dataSnapshot = await getDocs(q);

		let orders = dataSnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				externalId: data.externalId,
				model: data.model,
				isActive: data.isActive,
				totalAmount: data.totalAmount,
				manufacturer: data.manufacturer,
				createdDate: data.createdDate.toDate(),
				linkedIds: data.linkedIds,
				plate: data.plate,
				year: data.year,
				serviceGroups: data.serviceGroups,
				services: data.services,
				parts: data.parts,
				fipeCode: data.fipeCode,
				type: data.type,
				proceedings: data.proceedings,
				modelAdjustmentFactor: data.modelAdjustmentFactor,
			} as IOrderDto;
		});
		return orders;
	} catch (error: any) {
		console.error(error);
	}
	return [];
}

async function getOrder(id: string) {
	try {
		const docSnapshot = await getDoc(doc(db, Collections.orders, id));
		const data = docSnapshot.data();

		return (
			({
				...data,
				createdDate: data!.createdDate.toDate(),
			} as IOrderDto) ?? undefined
		);
	} catch (error) {
		console.error(error);
	}
}

// CREATE SECTION
async function addOrder(order: IOrderDto) {
	try {
		const existingOrder = await getOrder(order.externalId);
		if (existingOrder) {
			throw new Error("Id de orçamento já cadastrado no sistema");
		}
		await setDoc(doc(db, "orders", order.externalId), order);
	} catch (error: any) {
		console.error(error);
		throw new Error("Id de orçamento já cadastrado no sistema");
	}
}

// DELETE SECTION

// UPDATE SECTION
async function finishOrder(id: string) {
	try {
		await updateDoc(doc(db, Collections.orders, id), { isActive: false });
	} catch (error: any) {
		console.error(error);
	}
}

async function reactivateOrder(id: string) {
	try {
		await updateDoc(doc(db, Collections.orders, id), { isActive: true });
	} catch (error: any) {
		console.error(error);
	}
}

async function addServiceToOrder(
	orderId: string,
	proceedingId: string,
	serviceId: string,
	hourPrice: number
) {
	try {
		const order = await getOrder(orderId);
		const service = await getServiceDataById(serviceId);

		order?.proceedings.forEach((p: IProceedingDto) => {
			const serviceAlreadyExists = p.services?.some(
				(s: IServiceDto) => s.id === serviceId
			);

			if (serviceAlreadyExists) {
				throw new Error(
					`Serviço ${service!.description} já cadastrado no orçamento`
				);
			}
		});

		let proceeding = order?.proceedings.find(
			(p: IProceedingDto) => p.id === proceedingId
		);

		proceeding?.services?.push(service! as IOrderServiceDto);

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: order?.proceedings,
		});
	} catch (error: any) {
		throw error;
	}
}

async function removeServiceFromOrderProceeding(
	orderId: string,
	service: IServiceDto,
	proceeding: IProceedingDto
) {
	try {
		const order = await getOrder(orderId);

		let orderProceeding = order?.proceedings.find(
			(p: IProceedingDto) => p.id === proceeding.id
		);

		const newProceedingServices = orderProceeding!.services.filter(
			(s: IServiceDto) => s.description !== service.description
		);

		orderProceeding!.services = newProceedingServices;

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: order?.proceedings,
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function addPartToOrder(orderId: string, orderPart: IOrderPartsDto) {
	try {
		const order = await getOrder(orderId);

		const partAlreadyExists = order?.parts.some(
			(o: IOrderPartsDto) => o.id === orderPart.id
		);

		if (partAlreadyExists) {
			throw new Error("Peça já cadastrada no orçamento");
		}

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: [...order?.parts!, orderPart],
		});
	} catch (error) {
		console.error(error);
	}
}

async function addProccedingToOrder(
	orderId: string,
	orderProceeding: IProceedingDto
) {
	try {
		const order = await getOrder(orderId);

		const proceedingAlreadyExists = order?.proceedings.some(
			(o: IProceedingDto) => o.id === orderProceeding.id
		);

		if (proceedingAlreadyExists) {
			throw new Error("Procedimento já cadastrado no orçamento");
		}

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: [
				...order?.proceedings!,
				{ ...orderProceeding, value: Number(orderProceeding.value) },
			],
		});
	} catch (error) {
		console.error(error);
	}
}

async function updateProceedingInOrder(
	orderId: string,
	orderProceedingId: string,
	newData: IProceedingDto
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderProceeding = order?.proceedings.find(
			(o: IProceedingDto) => o.id === orderProceedingId
		);

		existentOrderProceeding!.description = newData.description!;
		existentOrderProceeding!.value = Number(newData.value);

		const newProceedings = updateItemInArrayById(
			order!.proceedings,
			orderProceedingId,
			existentOrderProceeding!
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: newProceedings,
		});
		await new Promise((f) => setTimeout(f, 2000));
	} catch (error) {
		console.error(error);
	}
}

async function updatePartsEstimates(
	orderId: string,
	orderPartId: string,
	newEstimate: IPartEstimateDto,
	idToRemoveBestOption?: string
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderPart = order?.parts.find(
			(o: IOrderPartsDto) => o.id === orderPartId
		);

		let newEstimates = existentOrderPart?.estimates;

		let existentEstimate = existentOrderPart?.estimates.find(
			(e: IPartEstimateDto) => e.id === newEstimate.id
		);

		if (!!existentEstimate) {
			newEstimates = updateItemInArrayById(
				newEstimates!,
				existentEstimate.id,
				newEstimate
			);
		} else {
			newEstimates?.push(newEstimate);
		}

		if (!!idToRemoveBestOption) {
			const oldBestOption = newEstimates?.find(
				(e: IPartEstimateDto) => e.id === idToRemoveBestOption
			);

			newEstimates = updateItemInArrayById(
				newEstimates!,
				idToRemoveBestOption,
				{ ...oldBestOption!, isBestOption: false }
			);
		}

		existentOrderPart!.estimates = newEstimates!;

		const newParts = updateItemInArrayById(
			order!.parts,
			orderPartId,
			existentOrderPart!
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: newParts,
		});
		await new Promise((f) => setTimeout(f, 1000));
	} catch (error) {
		console.error(error);
	}
}

async function updatePartInOrder(
	orderId: string,
	orderPartId: string,
	newData: IOrderPartsDto
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderPart = order?.parts.find(
			(o: IOrderPartsDto) => o.id === orderPartId
		);

		let newEstimates =
			existentOrderPart?.estimates.map((estimate: IPartEstimateDto) => {
				const newFactor = calculatePartEstimateProfitFactor(
					estimate.costPrice,
					newData.availability ?? ""
				);

				const newEstimate: IPartEstimateDto = {
					...estimate,
					profitFactor: newFactor,
				};

				return newEstimate;
			}) ?? [];

		existentOrderPart!.description = newData.description!;
		existentOrderPart!.availability = newData.availability!;
		existentOrderPart!.status = newData.status!;
		existentOrderPart!.estimates = newEstimates;

		const newParts = updateItemInArrayById(
			order!.parts,
			orderPartId,
			existentOrderPart!
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: newParts,
		});
		await new Promise((f) => setTimeout(f, 2000));
	} catch (error) {
		console.error(error);
	}
}

async function deletePartEstimate(
	orderId: string,
	orderPartId: string,
	idToRemove: string
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderPart = order?.parts.find(
			(op: IOrderPartsDto) => op.id === orderPartId
		);

		let newEstimates = existentOrderPart?.estimates;

		newEstimates = existentOrderPart!.estimates.filter(
			(e: IPartEstimateDto) => e.id !== idToRemove
		);

		let newParts = order?.parts.filter(
			(o: IOrderPartsDto) => o.id !== orderPartId
		);

		existentOrderPart!.estimates = newEstimates!;

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: [...newParts!, existentOrderPart],
		});
	} catch (error) {
		console.error(error);
	}
}

async function deleteOrderPart(orderId: string, orderPartId: string) {
	try {
		const order = await getOrder(orderId);

		let newParts = order?.parts.filter(
			(o: IOrderPartsDto) => o.id !== orderPartId
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: [...newParts!],
		});
	} catch (error) {
		console.error(error);
	}
}

async function deleteOrderProceeding(
	orderId: string,
	orderProceedingId: string
) {
	try {
		const order = await getOrder(orderId);

		let newProceedings = order?.proceedings.filter(
			(p: IProceedingDto) => p.id !== orderProceedingId
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: [...newProceedings!],
		});
	} catch (error) {
		console.error(error);
	}
}

async function importTemplateIntoOrder(
	orderId: string,
	templateId: string,
	proceedingId: string
) {
	try {
		const order = await getOrder(orderId);
		const template = await getTemplateById(templateId);

		let proceedingServices = order?.proceedings.find(
			(p: IProceedingDto) => p.id === proceedingId
		)?.services;

		template!.services.forEach(async (ts: IServiceDto) => {
			const serviceAlreadyExists = proceedingServices?.some(
				(os: IOrderServiceDto) => ts.id === os.id
			);

			if (!serviceAlreadyExists) {
				proceedingServices?.push(ts as IOrderServiceDto);
			}
		});

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: order?.proceedings,
		});
	} catch (error) {
		console.error(error);
	}
}

export {
	addOrder,
	addPartToOrder,
	addProccedingToOrder,
	addServiceToOrder,
	deleteOrderPart,
	deleteOrderProceeding,
	deletePartEstimate,
	finishOrder,
	getOrder,
	getOrderByExternalId,
	getOrders,
	importTemplateIntoOrder,
	reactivateOrder,
	removeServiceFromOrderProceeding,
	updatePartInOrder,
	updatePartsEstimates,
	updateProceedingInOrder,
};
