import { ptBR } from "@material-ui/core/locale";
import { createTheme } from "@mui/material";
import { ptBR as gridPtBR } from "@mui/material/locale";

const theme = createTheme(
	{
		palette: {
			mode: "light",
			primary: {
				main: "#ff7221",
				light: "#ffb426",
				contrastText: "#ffffff",
			},
			secondary: {
				main: "#f50057",
			},
			background: {
				default: "#303030",
				paper: "#424242",
			},
			text: {
				primary: "#FFFFFF",
				secondary: "#FFFFFF",
				disabled: "#FFFFFF",
			},
		},
	},
	gridPtBR,
	ptBR
);

export { theme };
