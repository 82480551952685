import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { Collections } from "../../Collections";
import { ICreateUser } from "../../types/User.types";
import { auth } from "../config/environments";
import { db, usersRef } from "../firestore";

async function getAllUsers() {
	try {
		var dataSnapshot = await getDocs(usersRef);

		dataSnapshot.forEach((doc) => {
			console.log(doc.id, "=>", doc.data());
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function getUserByUuid(uuid: string) {
	try {
		var docRef = doc(db, Collections.users, uuid);
		var dataSnapshot = await getDoc(docRef);

		return dataSnapshot.data();
	} catch (error: any) {
		console.error(error);
	}
}

async function authenticate(email: string, password: string) {
	try {
		await signInWithEmailAndPassword(auth, email, password);
	} catch (error: any) {
		console.error(error);
	}
}

async function signOut() {
	try {
		await auth.signOut();
	} catch (error: any) {
		console.error(error);
	}
}

async function createUser(user: ICreateUser) {
	try {
		const newUser = await createUserWithEmailAndPassword(
			auth,
			user.email,
			user.password
		);

		await setDoc(doc(db, Collections.users, newUser!.user!.uid), {
			name: user.name,
			surname: user.surname,
			email: user.email,
		});
	} catch (error: any) {
		console.error(error);
	}
}

export { authenticate, createUser, getAllUsers, getUserByUuid, signOut };
