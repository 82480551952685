export interface IOrderPartsFormInput {
	description: string;
	availability: "" | "Fácil" | "Médio" | "Difícil";
	status: "" | "Orçar" | "Solicitada" | "Entregue";
}

export const emptyOrderPartsForm: IOrderPartsFormInput = {
	description: "",
	availability: "",
	status: "",
};
