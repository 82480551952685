import { uuidv4 } from "@firebase/util";
import {
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import { ICarModelCreation, IFipeCodesData } from "../../types/CarModel.types";
import { IFipeModelo } from "../../types/Fipe.types";
import { IOptions } from "../../types/Options.types";
import {
	IGroupServiceDto,
	IGroupServiceServiceDto,
	IServiceCreation,
	IServiceDto,
} from "../../types/Services.types";
import { IGroupCreationForm } from "../../views/private/Models/EditModel/Tabs/Groups/GroupForm.form";
import { db } from "../firestore";

// READ SECTION
async function getAllModelsFromManufacturer(
	manufacturer: string
): Promise<any> {
	try {
		const manufacturerData = await getDoc(
			doc(db, Collections.manufacturers, manufacturer)
		);
		const data = manufacturerData.data();

		return data!.models.map((m: any) => {
			return { ...m, id: m.Value };
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function getModelById(id: string) {
	try {
		const docSnapshot = await getDoc(doc(db, Collections.models, id));
		return docSnapshot.data() ?? undefined;
	} catch (error) {
		console.error(error);
	}
}

// CREATE SECTION

// DELETE SECTION
async function deleteModelDocById(id: string) {
	try {
		await deleteDoc(doc(db, Collections.models, id));
	} catch (error) {
		console.error(error);
	}
}

// UPDATE SECTION
async function editModel(id: string, updatedModel: ICarModelCreation) {
	try {
		await updateDoc(doc(db, Collections.models, id), updatedModel);
	} catch (error: any) {
		console.error(error);
	}
}

async function addService(modelId: string, service: IServiceCreation) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		updateDoc(doc(db, "models", modelId), {
			services: arrayUnion({
				...service,
				duration: service.duration as number,
				id: uuidv4(),
			}),
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function updateService(modelId: string, service: IServiceDto) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		const oldServiceData = model
			.data()!
			.services.find((s: IServiceDto) => s.id === service.id);

		updateDoc(doc(db, Collections.models, modelId), {
			services: arrayRemove(oldServiceData),
		});

		updateDoc(doc(db, Collections.models, modelId), {
			services: arrayUnion({
				...service,
				duration: service.duration as number,
			}),
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function removeService(modelId: string, serviceId: string) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		const service = model
			.data()!
			.services.find((s: IServiceDto) => s.id === serviceId);

		updateDoc(doc(db, Collections.models, modelId), {
			services: arrayRemove(service),
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function addGroupService(modelId: string, group: IGroupCreationForm) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		updateDoc(doc(db, Collections.models, modelId), {
			serviceGroups: arrayUnion({
				...group,
				services: [],
			}),
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function removeGroupService(modelId: string, name: string) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		const group = model
			.data()!
			.serviceGroups!.find((g: IGroupServiceDto) => g.name === name);

		updateDoc(doc(db, Collections.models, modelId), {
			serviceGroups: arrayRemove(group),
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function addServiceToGroupService(
	modelId: string,
	groupName: string,
	service: IGroupServiceServiceDto
) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		const oldGroup = model
			.data()!
			.serviceGroups!.find((g: IGroupServiceDto) => g.name === groupName);

		const newGroup = { ...oldGroup };
		newGroup!.services.push(service);

		const newServiceGroups = model
			.data()!
			.serviceGroups!.filter(
				(g: IGroupServiceDto) => g.name !== groupName
			);

		newServiceGroups.push(newGroup);

		updateDoc(doc(db, Collections.models, modelId), {
			serviceGroups: newServiceGroups,
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function removeServiceFromGroupService(
	modelId: string,
	groupName: string,
	serviceId: string
) {
	try {
		const model = await getDoc(doc(db, Collections.models, modelId));
		if (!model) {
			throw new Error("Este modelo não está cadastrado no sistema");
		}

		const oldGroup = model
			.data()!
			.serviceGroups!.find((g: IGroupServiceDto) => g.name === groupName);

		const newGroup = { ...oldGroup };

		var serviceToRemove = newGroup!.services.find(
			(x: any) => x.id === serviceId
		);
		const serviceIndex = newGroup!.services.indexOf(serviceToRemove);

		if (serviceIndex > -1) {
			newGroup!.services.splice(serviceIndex, 1);
		}

		const newServiceGroups = model
			.data()!
			.serviceGroups!.filter(
				(g: IGroupServiceDto) => g.name !== groupName
			);

		newServiceGroups.push(newGroup);

		updateDoc(doc(db, Collections.models, modelId), {
			serviceGroups: newServiceGroups,
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function updateGroupService(
	modelId: string,
	payload: any,
	originalGroupName: string
) {
	const model = await getDoc(doc(db, Collections.models, modelId));
	if (!model) {
		throw new Error("Este modelo não está cadastrado no sistema");
	}

	const filteredGroups = model
		.data()!
		.serviceGroups!.filter(
			(g: IGroupServiceDto) => g.name !== originalGroupName
		);

	filteredGroups.push(payload);
	updateDoc(doc(db, Collections.models, modelId), {
		serviceGroups: filteredGroups,
	});
}

async function addFipeModels(
	modelos: IFipeModelo[],
	manufacturerData: IOptions
) {
	const batch = writeBatch(db);

	modelos.forEach((m: IFipeModelo) => {
		batch.set(
			doc(
				db,
				Collections.models,
				`${manufacturerData.id.toString()}_${m.Value.toString()}`
			),
			{
				name: m.Label,
				insideManufacturerCode: m.Value,
				manufacturerFipeCode: parseInt(manufacturerData.id),
				manufacturerName: manufacturerData.value,
				fullfilled: false,
				services: [],
			}
		);
	});

	await batch.commit();
}

async function formalizeModelOrders(
	fipeCodes: IFipeCodesData[],
	adjusmentFactor: number
) {
	try {
		const codes = fipeCodes.map((c: IFipeCodesData) => c.codigoFipe);
		const batch = writeBatch(db);
		const q = query(
			collection(db, Collections.orders),
			where("fipeCode", "in", codes),
			where("type", "==", "draft")
		);

		const querySnapshot = await getDocs(q);

		querySnapshot.forEach((d: any) => {
			const data = d.data();

			batch.update(doc(db, Collections.orders, d.id), {
				type: data.externalId.includes("MI") ? "detatched" : "order",
				modelAdjustmentFactor: adjusmentFactor,
			});
		});

		await batch.commit();
	} catch (error) {
		console.error(error);
	}
}

export {
	addFipeModels,
	addGroupService,
	addService,
	addServiceToGroupService,
	deleteModelDocById,
	editModel,
	formalizeModelOrders,
	getAllModelsFromManufacturer,
	getModelById,
	removeGroupService,
	removeService,
	removeServiceFromGroupService,
	updateGroupService,
	updateService,
};
