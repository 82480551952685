import { collection, getFirestore } from "firebase/firestore";
import { Collections } from "../../Collections";
import { app } from "../config/environments";

const db = getFirestore(app);

const modelsRef = collection(db, Collections.models);
const ordersRef = collection(db, Collections.orders);
const pricesRef = collection(db, Collections.prices);
const usersRef = collection(db, Collections.users);
const manufacturersRef = collection(db, Collections.manufacturers);

export { db, manufacturersRef, modelsRef, ordersRef, pricesRef, usersRef };
