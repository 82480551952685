import {
	AppBar,
	Button,
	CssBaseline,
	Divider,
	Drawer,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	Toolbar,
} from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import {
	Theme,
	createStyles,
	makeStyles,
	useTheme,
} from "@material-ui/core/styles";
import {
	AccountCircle,
	ChevronLeft,
	ChevronRight,
	Menu as MenuIcon,
} from "@material-ui/icons";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Avatar from "@mui/material/Avatar";
import clsx from "clsx";
import "font-awesome/css/font-awesome.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import icons from "../assets/icons/icons";
import logoImage from "../assets/logo.png";
import { getUserByUuid, signOut } from "../services/api/users";
import { auth } from "../services/config/environments";
import SvgIcon from "./Icons/SvgIcon";

interface IDrawerProps {
	children?: React.ReactNode;
}

interface IMenuSection {
	description: string;
	routes: {
		text: string;
		icon: React.ReactChild;
		route: string;
		roles: string[];
	}[];
}

const ServicesMenuItems: IMenuSection = {
	description: "Operação",
	routes: [
		{
			text: "Orçamentos",
			icon: <SvgIcon color="#ffffff" svgContent={icons.expenditure} />,
			route: "/home",
			roles: ["admin", "orcamentista"],
		},
		// {
		// 	text: 'Pagamentos',
		// 	icon: <SvgIcon svgContent={icons.payments} />,
		// 	route: '/payments',
		// },
	],
};

const VehicleMenuItems: IMenuSection = {
	description: "Cadastros",
	routes: [
		{
			text: "Modelos",
			icon: <DirectionsCarIcon fontSize="large" htmlColor="#ffffff" />,
			route: "/modelos",
			roles: ["admin", "mecanico", "orcamentista"],
		},
		{
			text: "Serviços",
			icon: <CarRepairIcon fontSize="large" htmlColor="#ffffff" />,
			route: "/servicos",
			roles: [],
		},
		{
			text: "Presets de Serviços",
			icon: <AssignmentIcon fontSize="large" htmlColor="#ffffff" />,
			route: "/presets",
			roles: [],
		},
		// {
		// 	text: "Fornecedores",
		// 	icon: <LocalShippingIcon fontSize="large" htmlColor="#ffffff" />,
		// 	route: "/fornecedores",
		// 	roles: [],
		// },
	],
};

const ManagementMenuItems: IMenuSection = {
	description: "Gerência",
	routes: [
		{
			text: "Definição de Preços",
			icon: <SvgIcon color="#ffffff" svgContent={icons.cost} />,
			route: "/price",
			roles: ["admin", "financeiro"],
		},
		{
			text: "Contingências",
			icon: <SvgIcon color="#ffffff" svgContent={icons.contingency} />,
			route: "/contingencias",
			roles: ["it-admin"],
		},
		// {
		// 	text: 'Meta de Lucro',
		// 	icon: <SvgIcon svgContent={icons.target} />,
		// 	route: '/target',
		// },
	],
};

const MenuItems = [ServicesMenuItems, VehicleMenuItems, ManagementMenuItems];

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			position: "relative",
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 24,
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: "60px",
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(8),
			},
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		toolbarButtons: {
			marginLeft: "auto",
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	})
);

export default function MiniDrawer(props: IDrawerProps) {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState<any>();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	let navigate = useNavigate();
	let location = useLocation();

	const getUser = useCallback(async () => {
		if (auth.currentUser !== null) {
			const userData = await getUserByUuid(auth.currentUser!.uid);
			setUser(userData);
		}
	}, []);

	useEffect(() => {
		getUser();
	}, [getUser]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	async function handleSignout() {
		try {
			await signOut();
			navigate("/");
		} catch (error: any) {
			console.error(error);
		}
	}

	const menuItemsList = useMemo(() => {
		return (
			<List>
				{MenuItems.map((itemsArr, index) => (
					<div key={`section-${index}`}>
						<p style={{ fontSize: "8px" }}>
							{itemsArr.description}
						</p>
						{itemsArr.routes.map((item) => (
							<Link
								key={`link-${item.text}`}
								id={`link-${item.text}`}
								to={item.route}
								style={{
									color: "#fff",
									textDecoration: "none",
								}}
							>
								<ListItem
									button
									key={item.text}
									style={{
										backgroundColor:
											location.pathname === item.route ||
											(item.route !== "/" &&
												location.pathname.includes(
													item.route
												))
												? "#303030"
												: "#424242",
									}}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText primary={item.text} />
								</ListItem>
							</Link>
						))}
					</div>
				))}
			</List>
		);
	}, [location.pathname]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Link to={"/home"}>
						<img
							src={logoImage}
							alt="Logo Mult Injection"
							height={45}
						/>
					</Link>
					<div className={classes.toolbarButtons}>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={openMenu}
							onClose={handleClose}
						>
							<Grid
								container
								direction="column"
								style={{ minWidth: "300px", padding: "12px" }}
							>
								<Grid
									direction="row"
									alignItems="center"
									container
									justifyContent="space-between"
									item
									spacing={2}
									style={{ marginBottom: "12px" }}
								>
									<Grid
										container
										item
										direction="column"
										spacing={0}
									>
										<p style={{ padding: 0, margin: 0 }}>
											{user?.name ?? ""}{" "}
											{user?.surname ?? ""}
										</p>
										<p style={{ padding: 0, margin: 0 }}>
											{auth.currentUser?.email}
										</p>
									</Grid>
									<Grid item>
										<Avatar
											sx={{ bgcolor: deepOrange[400] }}
										>
											{user?.name
												?.split(" ")[0]
												.charAt(0)}
											{user?.surname
												?.split(" ")[0]
												.charAt(0)}
										</Avatar>
									</Grid>
								</Grid>
								<Button
									style={{ justifyContent: "end" }}
									onClick={handleSignout}
								>
									Sair
								</Button>
							</Grid>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				style={{ backgroundColor: "#000000" }}
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? (
							<ChevronRight />
						) : (
							<ChevronLeft />
						)}
					</IconButton>
				</div>
				<Divider />
				{menuItemsList}
			</Drawer>
			{props.children}
		</div>
	);
}
