import { Button, Grid } from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import { useEffect, useState } from "react";
import FormModal from "../../../../../../components/Modals/FormModal";
import {
	IGroupServiceDto,
	IServiceDto,
} from "../../../../../../types/Services.types";
import GroupForm from "./GroupForm";
import { GroupsGrid } from "./GroupsGrid";

interface GroupsTabProps {
	id: string;
	groups: IGroupServiceDto[];
	refreshParentHandler: any;
	refreshParent: boolean;
	services: IServiceDto[];
	adjustmentFactor?: number;
}

export default function GroupsTab(props: GroupsTabProps) {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(props.refreshParent);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	useEffect(() => {
		props.refreshParentHandler(!refresh);
	}, [props, refresh]);

	return (
		<>
			{/* FormModal de adição e edição de grupo de serviço */}
			<FormModal
				title="Adicionar Grupo"
				description="Crie um grupo de serviços para o modelo selecionado adicionando serviços e ajustando o percentual a ser considerado em cada serviço"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="groupForm"
			>
				<GroupForm
					id={props.id}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>

			<Grid
				container
				style={{ marginBottom: "8px" }}
				justifyContent="flex-end"
			>
				<Button
					variant="contained"
					color="primary"
					endIcon={<PlaylistAdd />}
					size="small"
					onClick={() => setOpenModal(true)}
				>
					Adicionar Grupo{" "}
				</Button>
			</Grid>

			<GroupsGrid
				id={props.id}
				refresh={refresh}
				groups={props.groups}
				refreshParent={refresh}
				refreshParentHandler={setRefresh}
				services={props.services}
				adjustmentFactor={props.adjustmentFactor}
			/>
		</>
	);
}
