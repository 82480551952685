import { Button, Grid } from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import HeaderLine from "../../../components/Content/HeaderLine";
import FormModal from "../../../components/Modals/FormModal";
import { getServicesFromSystem } from "../../../services/api/services";
import { ISystemServiceDto } from "../../../types/Services.types";
import { serviceAttributes } from "../../../utils/data/serviceProperties";
import ServiceForm from "./ServiceForm";
import { ServicesGrid } from "./ServicesGrid";

export default function Services() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedSystem, setSelectedSystem] = useState<string>("");
	const [refresh, setRefresh] = useState<boolean>(true);
	const [services, setServices] = useState<ISystemServiceDto[]>([]);

	const servicesList = useCallback(async () => {
		try {
			const data = await getServicesFromSystem(selectedSystem);
			setServices(data?.services);
		} catch (error) {
			console.error(error);
		}
	}, [selectedSystem]);

	useEffect(() => {
		servicesList();
	}, [servicesList, refresh]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	return (
		<>
			<HeaderLine title="Serviços" />

			<Autocomplete
				style={{ marginBottom: "16px" }}
				disablePortal
				options={serviceAttributes.systemOptions}
				getOptionLabel={(option) => option.value}
				renderInput={(params) => (
					<TextField {...params} label="Sistema" variant="standard" />
				)}
				onChange={(e: any, v: any) => {
					setSelectedSystem(v ? v.id : "");
				}}
			/>

			<Grid
				container
				style={{ marginBottom: "8px" }}
				justifyContent="flex-end"
			>
				<Button
					variant="contained"
					color="primary"
					endIcon={<PlaylistAdd />}
					size="small"
					onClick={() => setOpenModal(true)}
				>
					Adicionar Serviço{" "}
				</Button>
			</Grid>

			<ServicesGrid
				services={services ?? []}
				system={selectedSystem}
				refreshParent={refresh}
				refreshParentHandler={setRefresh}
			/>

			{/* FormModal de adição e edição de serviço */}
			<FormModal
				title="Adicionar serviço"
				description="Crie um serviço"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="serviceForm"
				wide
			>
				<ServiceForm
					id={selectedSystem}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>
		</>
	);
}
