import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { addServiceToOrder } from "../../../../../../services/api/orders";
import { getServicesFromSystem } from "../../../../../../services/api/services";
import { IFormModalProps } from "../../../../../../types/Modal.types";
import { IOptions } from "../../../../../../types/Options.types";
import { IServiceDto } from "../../../../../../types/Services.types";
import { serviceAttributes } from "../../../../../../utils/data/serviceProperties";
import { parseServiceOptions } from "../../../../../../utils/helpers/parsers";
import {
	IOrderServiceFormInput,
	emptyOrderServiceForm,
} from "./OrderServiceForm.form";

export interface IOrderServiceFormProps extends IFormModalProps {
	services: IServiceDto[];
	modelPrice: number;
	proceedingId: string;
}

export default function OrderServiceForm(props: IOrderServiceFormProps) {
	const { control, handleSubmit, reset, watch } =
		useForm<IOrderServiceFormInput>({
			defaultValues: emptyOrderServiceForm,
		});
	const [loading, setLoading] = useState<boolean>(false);
	const [service, setService] = useState<string>("");
	const [serviceOptions, setServiceOptions] = useState<IOptions[]>([]);
	const watchSystem = watch("system");
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleClose = () => {
		reset(emptyOrderServiceForm);
		props.handleCloseModal(false);
	};

	const fetchServicesFromSystem = useCallback(async () => {
		try {
			const data = await getServicesFromSystem(watchSystem);
			setServiceOptions(parseServiceOptions(data!.services));
		} catch (error) {
			console.error(error);
		}
	}, [watchSystem]);

	useEffect(() => {
		fetchServicesFromSystem();
	}, [fetchServicesFromSystem]);

	const onSubmit: SubmitHandler<IOrderServiceFormInput> = async (
		data: IOrderServiceFormInput
	) => {
		setLoading(true);
		try {
			await addServiceToOrder(
				props.id,
				props.proceedingId,
				service,
				props.modelPrice
			);
			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="orderServiceForm" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<InputLabel id="system">Sistema</InputLabel>

					<Controller
						control={control}
						name="system"
						defaultValue={emptyOrderServiceForm.system}
						render={({ field: { onChange, value } }) => (
							<Select
								labelId="system"
								id="select-system"
								fullWidth
								onChange={onChange}
								value={value}
							>
								{serviceAttributes.systemOptions.map(
									(o: IOptions, i: number) => {
										return (
											<MenuItem key={i} value={o.id}>
												{o.value}
											</MenuItem>
										);
									}
								)}
							</Select>
						)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Controller
						control={control}
						name="service"
						defaultValue={emptyOrderServiceForm.system}
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								style={{ marginBottom: "16px" }}
								options={serviceOptions}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								noOptionsText="Nenhum serviço encontrado"
								getOptionLabel={(option: any) => option.value}
								renderInput={(params: any) => (
									<TextField {...params} label="Serviço" />
								)}
								onChange={(e: any, v: any) => {
									setService(v ? v.id : "");
								}}
							/>
						)}
					/>
				</FormControl>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { OrderServiceForm };
