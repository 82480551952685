import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./hooks/ProtectedRoute";
import { PublicRoute } from "./hooks/PublicRoute";
import Contingency from "./views/private/Contingency/Contingency";
import EditModel from "./views/private/Models/EditModel/EditModel";
import { Models } from "./views/private/Models/Models";
import OrderDetails from "./views/private/Orders/EditOrder/OrderDetails";
import Orders from "./views/private/Orders/Orders";
import { Payments } from "./views/private/Payments/Payments";
import Price from "./views/private/Price/Price";
import Services from "./views/private/Services/Services";
import ServiceTemplates from "./views/private/ServicesTemplates/ServiceTemplates";
import { Login } from "./views/public/Login";
import NotFound from "./views/public/NotFound";
import { SignUp } from "./views/public/SignUp";

export function AppRoutes() {
	return (
		<Routes>
			<Route element={<PublicRoute />}>
				<Route path="/" element={<Login />} />
				<Route path="/cadastro" element={<SignUp />} />
			</Route>
			<Route element={<ProtectedRoute />}>
				<Route path="/payments" element={<Payments />} />
				<Route path="/servicos" element={<Services />} />
				<Route path="/presets" element={<ServiceTemplates />} />

				<Route path="/modelos" element={<Models />} />
				<Route
					path="/modelos/:manufacturerId/:modelId"
					element={<EditModel />}
				/>
				<Route path="/price" element={<Price />} />
				<Route path="/home" element={<Orders />} />
				<Route path="/order/:id" element={<OrderDetails />} />
				{/* <PrivateRoute path='/users' element={Users} /> */}
				<Route path="/contingencias" element={<Contingency />} />
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}
