import { IOptions } from "../../types/Options.types";

const partsAvailabilityOptions: IOptions[] = [
	{ id: "easy", value: "Fácil" },
	{ id: "medium", value: "Médio" },
	{ id: "hard", value: "Difícil" },
];

const partsStatusOptions: IOptions[] = [
	{ id: "ask", value: "Orçar" },
	{ id: "requested", value: "Solicitada" },
	{ id: "delivered", value: "Entregue" },
];

export const partsProperties = {
	partsAvailabilityuOptions: partsAvailabilityOptions,
	partsStatusOptions: partsStatusOptions,
};
