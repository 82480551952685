import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { getServicesFromSystem } from "../../../../../services/api/services";
import { IOptions } from "../../../../../types/Options.types";
import { serviceAttributes } from "../../../../../utils/data/serviceProperties";
import { parseServiceOptions } from "../../../../../utils/helpers/parsers";
import {
	IServiceTemplateServiceFormInput,
	emptyServiceTemplateServiceForm,
} from "./ServiceTemplateServiceForm.form";

export interface IGroupServiceFormProps {
	addServiceHandler: any;
	refreshParentHandler?: any;
	refreshParent?: boolean;
	handleCloseModal: any;
}

export default function ServiceTemplateServiceForm(
	props: IGroupServiceFormProps
) {
	const { control, handleSubmit, reset, watch } =
		useForm<IServiceTemplateServiceFormInput>({
			defaultValues: emptyServiceTemplateServiceForm,
		});
	const [service, setService] = useState<string>("");
	const [serviceOptions, setServiceOptions] = useState<IOptions[]>([]);
	const watchSystem = watch("system");

	const getServiceOptions = useCallback(async () => {
		try {
			const services = await getServicesFromSystem(watchSystem);
			setServiceOptions(parseServiceOptions(services!.services));
		} catch (error) {
			console.error(error);
		}
	}, [watchSystem]);

	useEffect(() => {
		getServiceOptions();
	}, [getServiceOptions]);

	const handleClose = () => {
		reset(emptyServiceTemplateServiceForm);
		props.handleCloseModal(false);
	};

	const onSubmit: SubmitHandler<IServiceTemplateServiceFormInput> = async (
		data: IServiceTemplateServiceFormInput
	) => {
		try {
			props.addServiceHandler(service);
			handleClose();
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<div>
			<form id="templateServiceFormId" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<InputLabel id="system">Sistema</InputLabel>

					<Controller
						control={control}
						name="system"
						defaultValue={emptyServiceTemplateServiceForm.system}
						render={({ field: { onChange, value } }) => (
							<Select
								labelId="system"
								id="select-system"
								fullWidth
								onChange={onChange}
								value={value}
							>
								{serviceAttributes.systemOptions.map(
									(o: IOptions, i: number) => {
										return (
											<MenuItem key={i} value={o.id}>
												{o.value}
											</MenuItem>
										);
									}
								)}
							</Select>
						)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Controller
						control={control}
						name="service"
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								style={{ marginBottom: "16px" }}
								options={serviceOptions}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								noOptionsText="Nenhum serviço encontrado"
								getOptionLabel={(option: any) => option.value}
								renderInput={(params: any) => (
									<TextField {...params} label="Serviço" />
								)}
								onChange={(e: any, v: any) => {
									setService(!!v ? v.id : "");
								}}
							/>
						)}
					/>
				</FormControl>
			</form>
		</div>
	);
}

export { ServiceTemplateServiceForm };
