import { uuidv4 } from "@firebase/util";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import {
	addProccedingToOrder,
	updateProceedingInOrder,
} from "../../../../../../services/api/orders";
import { IFormModalProps } from "../../../../../../types/Modal.types";
import { IProceedingDto } from "../../../../../../types/Order.types";
import { emptyOrderProceedingForm } from "./OrderProceedingsForm.form";

interface IPartFormModalProps extends IFormModalProps {
	proceeding?: IProceedingDto;
}

export default function OrderProceedingsForm(props: IPartFormModalProps) {
	const { control, handleSubmit, reset } = useForm<IProceedingDto>({
		defaultValues: emptyOrderProceedingForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleClose = () => {
		reset(emptyOrderProceedingForm);
		props.handleCloseModal(false);
	};

	useEffect(() => {
		reset({
			description: props.proceeding?.description,
			value: props.proceeding?.value,
		});
	}, [props.proceeding?.description, props.proceeding?.value, reset]);

	const onSubmit: SubmitHandler<IProceedingDto> = async (
		data: IProceedingDto
	) => {
		setLoading(true);
		try {
			if (props.proceeding) {
				await updateProceedingInOrder(
					props.id,
					props.proceeding.id,
					data
				);
			} else {
				await addProccedingToOrder(props.id, {
					...data,
					services: [],
					id: uuidv4(),
				});
			}

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: `Procedimento ${
					!!props?.proceeding ? "atualizado" : "adicionado"
				}  com sucesso`,
				severity: "success",
			});

			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="orderProceedingsForm" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<Controller
						name="description"
						control={control}
						defaultValue={emptyOrderProceedingForm.description}
						render={({ field: { onChange, value } }) => (
							<TextField
								autoFocus
								margin="dense"
								id="diagnosis"
								label="Diagnóstico/Procedimento"
								type="text"
								fullWidth
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				</FormControl>

				<FormControl fullWidth>
					<Controller
						name="value"
						control={control}
						defaultValue={emptyOrderProceedingForm.value}
						render={({ field: { onChange, value } }) => (
							<TextField
								autoFocus
								margin="dense"
								id="value"
								label="Valor"
								type="number"
								InputProps={{
									inputProps: {
										min: 0,
										max: 999999999999,
										step: 0.01,
									},
									startAdornment: {
										...(
											<InputAdornment position="start">
												R$
											</InputAdornment>
										),
									},
								}}
								fullWidth
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				</FormControl>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { OrderProceedingsForm };
