import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ActionButtons } from "../../../components/GridActions";
import FormModal from "../../../components/Modals/FormModal";
import {
	deleteTemplateById,
	getTemplateById,
} from "../../../services/api/templates";
import ServiceTemplateDetails from "./Templates/TemplatesDetails/ServiceTemplateDetails";

interface ITempltatesGridProps {
	refreshParentHandler: any;
	refreshParent: boolean;
}

function ServiceTemplatesGrid(props: ITempltatesGridProps) {
	const [data, setData] = useState<any[]>([]);
	const [templateId, setTemplateId] = useState<string>();
	const [openModal, setOpenModal] = useState<boolean>(false);

	const getRows = useCallback(async () => {
		const templates = await getTemplateById("all_templates");

		setData(templates?.templates ?? []);
	}, []);

	useEffect(() => {
		getRows();
	}, [getRows, props.refreshParent]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	function onEditGroup(id: string) {
		setTemplateId(id);
		setOpenModal(true);
	}

	async function onDelete(id: string) {
		// eslint-disable-next-line no-restricted-globals
		const mustDelete: boolean = confirm(
			"Você tem certeza disso? Esta ação não poderá ser desfeita..."
		);
		if (mustDelete) {
			try {
				await deleteTemplateById(id);
				props.refreshParentHandler(!props.refreshParent);
			} catch (error: any) {
				console.error(error);
			}
		} else {
			alert("A ação foi cancelada.");
		}
	}

	const columns: GridColDef[] = [
		{
			field: "description",
			headerName: "Nome do Template",
			flex: 1,
			headerClassName: "super-app-theme--header",
		},
		{
			field: " ",
			headerName: "Ações",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			renderCell: (obj) => (
				<>
					<ActionButtons
						onDelete={() => onDelete(obj.row.id)}
						onEdit={() => onEditGroup(obj.row.id)}
					/>
				</>
			),
			flex: 0.7,
			align: "center",
		},
	];

	return (
		<>
			<FormModal
				title="Detalhes do Grupo de Serviço"
				wide
				handleCancel={handleCancel}
				isOpen={openModal}
				formToSubmitId="templateDetailsForm"
			>
				<ServiceTemplateDetails
					templateId={templateId!}
					refreshParentHandler={props.refreshParentHandler}
					refreshParent={props.refreshParent}
					closeModal={setOpenModal}
				/>
			</FormModal>
			<Box
				sx={{
					height: 300,
					width: "100%",
					"& .super-app-theme--header": {
						backgroundColor: "#ff7221",
					},
				}}
			>
				<DataGrid
					disableSelectionOnClick
					density="compact"
					rows={data}
					getRowId={(row: any) => row.description.replace(" ", "")}
					columns={columns}
					pageSize={10}
					components={{
						NoRowsOverlay: () => (
							<>
								<Box
									sx={{
										mt: 1,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "flex-end",
										height: "20%",
									}}
								>
									Não há templates cadastrados
								</Box>
							</>
						),
					}}
				/>
			</Box>
		</>
	);
}
export { ServiceTemplatesGrid };
