import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { auth } from "../../services/config/environments";

const baseUser: any = {
	name: "",
	isAuthenticated: false,
};

const AuthContext = createContext(baseUser);

function AuthProvider({ children }: any) {
	const [currentUser, setCurrentUser] = useState(baseUser);
	const [pending, setPending] = useState(true);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			setCurrentUser(user);
			setPending(false);
		});
	}, []);

	if (pending) {
		return <>Loading...</>;
	}

	return (
		<AuthContext.Provider value={{ currentUser }}>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
