import axios from "axios";
import Endpoints from "../../Endpoints";
import { IApiPlacasQuotas, IPlacasData } from "../../types/ApiPlacas.types";

async function getDataByPlate(plate: string): Promise<IPlacasData> {
	try {
		console.log("Chamando API Placas para placa " + plate.toUpperCase());
		const res = await axios.get(
			Endpoints.apiplacas.getData(plate.toUpperCase())
		);
		console.log(res);
		return res.data as IPlacasData;
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar dados da placa");
	}
}

async function getQuotas(): Promise<IApiPlacasQuotas> {
	try {
		const res = await axios.get(Endpoints.apiplacas.getQuotas);
		return res.data as IApiPlacasQuotas;
	} catch (error) {
		console.error(error);
		throw new Error("Erro ao buscar quotas");
	}
}

export { getDataByPlate, getQuotas };
