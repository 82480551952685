import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CardsGrid from "../../../components/Content/CardsGrid";
import HeaderLine from "../../../components/Content/HeaderLine";
import Loading from "../../../components/Loading";
import BottomLine from "../../../components/Separators/BottomLine";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { Header } from "../../../components/Typography/Typography";
import { addManufacturer } from "../../../services/api/manufacturers";
import { addFipeModels } from "../../../services/api/models";
import {
	getManufacturerModels,
	getManufacturers,
} from "../../../services/fipe/fipe";
import { IOptions } from "../../../types/Options.types";
import { parseFipeMarcasOptions } from "../../../utils/helpers/parsers";

export default function Contingency() {
	const [manufacturersOptions, setManufacturersOptions] = useState<
		IOptions[]
	>([]);
	const [selectedManufacturer, setSelectedManufactuer] = useState<IOptions>();
	const [loading, setLoading] = useState<boolean>(false);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const saveModelsForManufacturer = async () => {
		try {
			setLoading(true);

			const models = (
				await getManufacturerModels(parseInt(selectedManufacturer!.id))
			).Modelos;

			await addManufacturer(selectedManufacturer!, models);
			await addFipeModels(models, selectedManufacturer!);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: `Modelos para a montadora ${selectedManufacturer?.value} adicionados`,
				severity: "success",
			});
		} catch (error) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message:
					"Erro ao buscar modelos. Entre em contato com o desenvolvedor.",
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const getManufacturersOptions = useCallback(async () => {
		try {
			const res = await getManufacturers();
			const options = parseFipeMarcasOptions(res);

			setManufacturersOptions(options);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getManufacturersOptions();
	}, [getManufacturersOptions]);

	return (
		<>
			{loading && <Loading />}

			<HeaderLine title="Contingencias" />

			<CardsGrid>
				<>
					<Grid
						item
						xs={12}
						md={4}
						lg={4}
						style={{
							display: "flex",
							flexDirection: "column",
							marginBottom: "8px",
						}}
					>
						<Card
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								height: "100%",
							}}
						>
							<Grid container alignItems={"flex-end"}>
								<CardContent
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										padding: "8px",
									}}
								>
									<Header text="Cadastro de Veículos via API Fipe" />
									<BottomLine />
									<br />
									<Typography align="left">
										Cadastro de modelos via Fipe para
										montadora solicitada diretamente da
										tabela Fipe e realiza o Salvamento no
										Firestore
									</Typography>
									<Autocomplete
										disablePortal
										id="combo-box-demo"
										options={manufacturersOptions}
										getOptionLabel={(option) =>
											option.value
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Montadora"
											/>
										)}
										onChange={(e: any, v: any) => {
											setSelectedManufactuer(
												v as IOptions
											);
										}}
									/>
								</CardContent>
								<CardActions
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										width: "100%",
									}}
								>
									<Button
										size="small"
										variant="contained"
										color="primary"
										onClick={() =>
											saveModelsForManufacturer()
										}
									>
										Cadastrar dados da montadora
									</Button>
								</CardActions>
							</Grid>
						</Card>
					</Grid>

					<Grid
						item
						xs={12}
						md={4}
						lg={4}
						style={{
							display: "flex",
							flexDirection: "column",
							marginBottom: "8px",
						}}
					>
						<Card
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								height: "100%",
							}}
						>
							<Grid container alignItems={"flex-end"}>
								<CardContent
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										padding: "8px",
									}}
								>
									<Header text="Solicitação de Adição de Veículo" />
									<BottomLine />
									<br />
									<Typography align="left">
										Solicite a Adição de um novo veículo ao
										Sistema
									</Typography>
								</CardContent>
								<CardActions
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										width: "100%",
									}}
								>
									<Button
										target="_blank"
										size="small"
										variant="contained"
										color="primary"
										href="https://forms.gle/w1p3wNmXimykFGNHA"
									>
										Solicitar
									</Button>
								</CardActions>
							</Grid>
						</Card>
					</Grid>

					<Grid
						item
						xs={12}
						md={4}
						lg={4}
						style={{
							display: "flex",
							flexDirection: "column",
							marginBottom: "8px",
						}}
					>
						<Card
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								height: "100%",
							}}
						>
							<Grid container alignItems={"flex-end"}>
								<CardContent
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										padding: "8px",
									}}
								>
									<Header text="Solicitação de Adição de Serviços" />
									<BottomLine />
									<br />
									<Typography align="left">
										Solicite a Adição de serviços a um
										veículo específico do Sistema
									</Typography>
								</CardContent>
								<CardActions
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										width: "100%",
									}}
								>
									<Button
										target="_blank"
										size="small"
										variant="contained"
										color="primary"
										href="https://forms.gle/adtkkgu4jhzzPMHr7"
									>
										Solicitar
									</Button>
								</CardActions>
							</Grid>
						</Card>
					</Grid>
				</>
			</CardsGrid>

			<CardsGrid>
				<></>
			</CardsGrid>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}
