import { Button, Grid, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import CardsGrid from "../../../components/Content/CardsGrid";
import HeaderLine from "../../../components/Content/HeaderLine";
import OSCard from "../../../components/Content/OSCard";
import FormModal from "../../../components/Modals/FormModal";
import { getOrderByExternalId, getOrders } from "../../../services/api/orders";
import { getCurrentPrice } from "../../../services/api/prices";
import { IOrderDto } from "../../../types/Order.types";
import OrderForm from "./OrderForm";

export default function Orders() {
	const [refresh, setRefresh] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [data, setData] = useState<IOrderDto[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [showActiveOrders, setShowActiveOrders] = useState<boolean>(true);
	const [sellPrice, setSellPrice] = useState<number>(0);

	const priceData = useCallback(async () => {
		const price = await getCurrentPrice();
		setSellPrice(price?.sellPrice ?? 0);
	}, []);

	useEffect(() => {
		priceData();
	}, [priceData]);

	useEffect(() => {
		async function getAllOrders() {
			try {
				const orders = await getOrders(showActiveOrders);
				setData(orders);
			} catch (error: any) {
				console.error(error);
			}
		}
		setSearchTerm("");

		getAllOrders();
	}, [refresh, showActiveOrders]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	const searchOrder = () => async () => {
		try {
			const orders = await getOrderByExternalId(searchTerm);
			setData(
				orders.filter((o: IOrderDto) =>
					o.externalId.includes(searchTerm)
				)
			);
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<>
			<HeaderLine
				title="Orçamentos"
				buttonText="Criar Orçamento"
				buttonAction={() => setOpenModal(true)}
			>
				<>
					<Grid
						style={{ margin: 0, padding: 0 }}
						direction="row"
						container
						spacing={1}
						item
						lg={4}
						alignItems="flex-end"
					>
						<Grid item>
							<Button
								onClick={() => {
									setShowActiveOrders(!showActiveOrders);
									setRefresh(!refresh);
								}}
								size="small"
								variant="outlined"
								color="primary"
							>
								{`Mostrando: ${
									showActiveOrders ? "Ativos" : "Inativos"
								}`}
							</Button>
						</Grid>
						<Grid item>
							<Button
								onClick={() => setRefresh(!refresh)}
								size="small"
								variant="outlined"
								color="primary"
							>
								Limpar filtros e Buscas
							</Button>
						</Grid>
					</Grid>
				</>
			</HeaderLine>

			<Grid container justifyContent="flex-start">
				<Grid item sm={11}>
					<TextField
						style={{ marginBottom: "16px", width: "100%" }}
						id="standard-basic"
						label="Busque um orçamento pelo ID Externo"
						variant="standard"
						value={searchTerm}
						onChange={(v) => setSearchTerm(v.target.value)}
					/>
				</Grid>
				<Grid item sm={1}>
					<Button
						aria-label="search"
						style={{ color: "white" }}
						size="large"
						onClick={searchOrder()}
						startIcon={<Search />}
					>
						Buscar
					</Button>
				</Grid>
			</Grid>

			<CardsGrid>
				<>
					{data.map((item, index) => {
						return (
							<OSCard price={sellPrice} item={item} key={index} />
						);
					})}
				</>
			</CardsGrid>

			<FormModal
				title="Criar Orçamento"
				description="Selecione a montadora e o modelo para o qual deseja criar um orçamento."
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="orderForm"
			>
				<>
					<OrderForm
						refreshParent={refresh}
						refreshParentHandler={setRefresh}
						handleCloseModal={setOpenModal}
					/>
				</>
			</FormModal>
		</>
	);
}
