import { uuidv4 } from "@firebase/util";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import {
	addPartToOrder,
	updatePartInOrder,
} from "../../../../../../services/api/orders";
import { IFormModalProps } from "../../../../../../types/Modal.types";
import { IOptions } from "../../../../../../types/Options.types";
import { IOrderPartsDto } from "../../../../../../types/Parts.types";
import { partsProperties } from "../../../../../../utils/data/partsProperties";
import { emptyOrderPartsForm } from "./OrderPartsForm.form";

interface IPartFormModalProps extends IFormModalProps {
	part?: IOrderPartsDto;
}

export default function OrderPartsForm(props: IPartFormModalProps) {
	const { control, handleSubmit, reset } = useForm<IOrderPartsDto>({
		defaultValues: emptyOrderPartsForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleClose = () => {
		reset(emptyOrderPartsForm);
		props.handleCloseModal(false);
	};

	useEffect(() => {
		reset({
			description: props.part?.description,
			availability: props.part?.availability,
			status: props.part?.status,
		});
	}, [props.part, reset]);

	const onSubmit: SubmitHandler<IOrderPartsDto> = async (
		data: IOrderPartsDto
	) => {
		setLoading(true);
		try {
			if (props.part) {
				await updatePartInOrder(props.id, props.part.id, data);
			} else {
				await addPartToOrder(props.id, {
					...data,
					estimates: [],
					id: uuidv4(),
				});
			}

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: `Peça ${
					!!props?.part ? "atualizada" : "adicionada"
				}  com sucesso`,
				severity: "success",
			});

			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="orderPartsForm" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<Controller
						name="description"
						control={control}
						defaultValue={emptyOrderPartsForm.description}
						render={({ field: { onChange, value } }) => (
							<TextField
								autoFocus
								margin="dense"
								id="part"
								label="Peça"
								type="text"
								fullWidth
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				</FormControl>

				<FormControl fullWidth>
					<Controller
						name="availability"
						control={control}
						defaultValue={emptyOrderPartsForm.availability}
						render={({ field: { onChange, value } }) => (
							<>
								<InputLabel
									variant="standard"
									id="availability-select"
								>
									Dificuldade de encontrar
								</InputLabel>
								<Select
									margin="dense"
									autoFocus
									id="part-availability"
									value={value}
									labelId="availability-select"
									onChange={onChange}
								>
									{partsProperties.partsAvailabilityuOptions.map(
										(o: IOptions, i: number) => (
											<MenuItem key={i} value={o.id}>
												{o.value}
											</MenuItem>
										)
									)}
								</Select>
							</>
						)}
					/>
				</FormControl>

				<FormControl fullWidth>
					<Controller
						name="status"
						control={control}
						defaultValue={emptyOrderPartsForm.status}
						render={({ field: { onChange, value } }) => (
							<>
								<InputLabel
									variant="standard"
									id="status-select"
								>
									Status
								</InputLabel>
								<Select
									margin="dense"
									autoFocus
									id="part-status"
									value={value}
									labelId="status-select"
									onChange={onChange}
								>
									{partsProperties.partsStatusOptions.map(
										(o: IOptions, i: number) => (
											<MenuItem key={i} value={o.id}>
												{o.value}
											</MenuItem>
										)
									)}
								</Select>
							</>
						)}
					/>
				</FormControl>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { OrderPartsForm };
