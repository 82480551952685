import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionButtons } from "../../../../../../components/GridActions";
import FormModal from "../../../../../../components/Modals/FormModal";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { removeService } from "../../../../../../services/api/models";
import {
	IGroupServiceDto,
	IServiceDto,
} from "../../../../../../types/Services.types";
import { serviceAttributes } from "../../../../../../utils/data/serviceProperties";
import {
	getOptionValueById,
	parseDecimalToHoursAndMinutes,
} from "../../../../../../utils/helpers/parsers";
import ServiceForm from "./ServiceForm";

interface IServiceGridProps {
	id: string;
	refresh: boolean;
	services: IServiceDto[];
	price: number;
	adjustmentFactor: number;
	refreshParent: boolean;
	refreshParentHandler: any;
	serviceGroups: IGroupServiceDto[];
}

function ModelServicesGrid(props: IServiceGridProps) {
	const [data, setData] = useState<IServiceDto[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedService, setSelectedService] = useState<IServiceDto>();
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	useEffect(() => {
		setData(props.services);
	}, [props.services]);

	function onEditService(serviceId: string) {
		const serviceToEdit = data.find((s) => s.id === serviceId);
		setSelectedService(serviceToEdit);
		setOpenModal(true);
	}

	async function onDelete(modelId: string, serviceId: string) {
		// eslint-disable-next-line no-restricted-globals
		const mustDelete: boolean = confirm(
			"Você tem certeza disso? Esta ação não poderá ser desfeita..."
		);

		const isUsedInGroup =
			props.serviceGroups.filter((service) =>
				service.services.some((s) => s.id === serviceId)
			).length > 0;

		if (mustDelete) {
			try {
				if (isUsedInGroup) {
					throw new Error(
						"Serviço é usado em algum grupo, valide antes de excluir."
					);
				}
				await removeService(modelId, serviceId);
				props.refreshParentHandler(!props.refreshParent);

				setSmartSnackbarConfig({
					open: !smartSnackbarConfig.open,
					message: "Serviço excluído com sucesso",
					severity: "success",
				});
			} catch (error: any) {
				setSmartSnackbarConfig({
					open: !smartSnackbarConfig.open,
					message: error.message as string,
					severity: "error",
				});

				console.error(error);
			}
		} else {
			alert("A ação foi cancelada.");
		}
	}

	const columns: GridColDef[] = [
		{
			field: "description",
			headerName: "Descrição",
			flex: 1,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "system",
			headerName: "Sistema",
			flex: 0.4,
			headerClassName: "super-app-theme--header",
			renderCell: (obj) => (
				<>
					<p>
						{getOptionValueById(
							obj.row.system,
							serviceAttributes.systemOptions
						)}
					</p>
				</>
			),
		},
		{
			field: "duration",
			headerName: "Duração",
			headerClassName: "super-app-theme--header",
			flex: 0.3,
			renderCell: (obj) => (
				<>
					<p>{parseDecimalToHoursAndMinutes(obj.row.duration)}</p>
				</>
			),
		},
		{
			field: "price",
			headerName: "Preço",
			headerClassName: "super-app-theme--header",
			flex: 0.3,
			renderCell: (obj) => (
				<>
					<p>{`R$${(
						obj.row.duration *
						props.adjustmentFactor *
						props.price
					).toFixed(2)}`}</p>
				</>
			),
		},
		{
			field: " ",
			headerName: "Ações",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			renderCell: (obj) => (
				<>
					<ActionButtons
						onDelete={() => onDelete(props.id, obj.row.id)}
						onEdit={() => onEditService(obj.row.id)}
					/>
				</>
			),
			flex: 0.7,
			align: "center",
		},
	];

	return (
		<>
			<Box
				sx={{
					height: 300,
					width: "100%",
					"& .super-app-theme--header": {
						backgroundColor: "#ff7221",
					},
				}}
			>
				<DataGrid
					disableSelectionOnClick
					density="compact"
					autoHeight
					rows={data}
					getRowId={(row: any) =>
						row.description + row.duration.toString()
					}
					columns={columns}
					pageSize={15}
					components={{
						NoRowsOverlay: () => (
							<>
								<Box
									sx={{
										mt: 1,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "flex-end",
										height: "20%",
									}}
								>
									Não há serviços para este modelo
								</Box>
							</>
						),
					}}
				/>
			</Box>

			<FormModal
				title="Adicionar serviço"
				description="Crie um serviço para o modelo selecionado adicionando uma descrição e o tempo de duração em horas"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="serviceForm"
				wide
			>
				<ServiceForm
					id={props.id}
					service={selectedService}
					refreshParent={props.refreshParent}
					refreshParentHandler={props.refreshParentHandler}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}
export { ModelServicesGrid };
