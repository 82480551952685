import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import BottomLine from "../Separators/BottomLine";
import { Title } from "../Typography/Typography";

const BottomSpacedDiv = styled.div`
	margin-bottom: 16px;
`;

interface IHeaderLineProps {
	title: string;
	buttonText?: string;
	buttonAction?: any;
	children?: JSX.Element;
}

export default function HeaderLine(props: IHeaderLineProps) {
	return (
		<BottomSpacedDiv>
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Title text={props.title} />
				{props.children}
				{props.buttonText && (
					<>
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={
								props.buttonAction ??
								(() =>
									console.warn(
										"No action attributed to tis button"
									))
							}
						>
							{props.buttonText}
						</Button>
					</>
				)}
			</Grid>
			<BottomLine />
		</BottomSpacedDiv>
	);
}
