import {
	Button,
	Chip,
	Grid,
	IconButton,
	Paper,
	Typography,
} from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../../../components/Loading";
import FormModal from "../../../../../../components/Modals/FormModal";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { Header } from "../../../../../../components/Typography/Typography";
import { getModelById } from "../../../../../../services/api/models";
import {
	deleteOrderProceeding,
	getOrder,
	removeServiceFromOrderProceeding,
} from "../../../../../../services/api/orders";
import { getCurrentPrice } from "../../../../../../services/api/prices";
import { IOrderDto, IProceedingDto } from "../../../../../../types/Order.types";
import { IOrderServiceDto } from "../../../../../../types/Services.types";
import { serviceAttributes } from "../../../../../../utils/data/serviceProperties";
import {
	getOptionValueById,
	parseDecimalToHoursAndMinutes,
} from "../../../../../../utils/helpers/parsers";
import OrderImportTemplateForm from "./OrderImportTemplateForm";
import OrderProceedingsForm from "./OrderProceedingsForm";
import OrderServiceForm from "./OrderServiceForm";

export default function OrderServiceDetails() {
	let { id } = useParams<{ id: string }>();
	const [order, setOrder] = useState<IOrderDto>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openProceedingsModal, setOpenProceedingsModal] =
		useState<boolean>(false);
	const [openImportTemplateModal, setOpenImportTemplateModal] =
		useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [orderProceedings, setOrderProceedings] = useState<IProceedingDto[]>(
		[]
	);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [modelPrice, setModelPrice] = useState<number>(0);
	const [sellPrice, setSellPrice] = useState<number>(0);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});
	const [selectedProceeding, setSelectedProceeding] = useState<
		IProceedingDto | undefined
	>();
	const [loading, setLoading] = useState<boolean>(false);

	async function removeOrderProceeding(proceedingId: string) {
		try {
			setLoading(true);
			await deleteOrderProceeding(id!, proceedingId);
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	const editProceeding = (e: any) => {
		setSelectedProceeding(e);
		setOpenProceedingsModal(true);
	};

	const addServiceToProceeding = (e: any) => {
		setSelectedProceeding(e);
		setOpenModal(true);
	};

	const importTemplateToProceeding = (e: any) => {
		setSelectedProceeding(e);
		setOpenImportTemplateModal(true);
	};

	async function removeService(
		service: IOrderServiceDto,
		proceeding: IProceedingDto
	) {
		try {
			const serviceToRemove = proceeding?.services?.find(
				(s) => s.description === service.description
			);

			await removeServiceFromOrderProceeding(
				id!,
				serviceToRemove!,
				proceeding
			);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Removido com sucesso",
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setRefresh(!refresh);
		}
	}

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
			setOpenImportTemplateModal(false);
			setOpenProceedingsModal(false);
			setSelectedProceeding(undefined);
		}
	}

	const modelData = useCallback(async () => {
		if (!!order?.linkedIds) {
			const modelData = await getModelById(order?.linkedIds[0]);
			setModelPrice((modelData?.adjustmentFactor ?? 0) * sellPrice!);
		}
	}, [order, sellPrice]);

	const priceData = useCallback(async () => {
		const price = await getCurrentPrice();
		setSellPrice(price?.sellPrice ?? 0);
	}, []);

	const orderData = useCallback(async () => {
		try {
			const data = await getOrder(id!);
			setOrder(data as IOrderDto);
			setSelectedProceeding(undefined);
		} catch (error: any) {
			console.error(error);
		} finally {
		}
	}, [id]);

	useEffect(() => {
		orderData();
	}, [orderData, refresh]);

	useEffect(() => {
		priceData();
		modelData();
	}, [priceData, modelData, refresh]);

	useEffect(() => {
		setOrderProceedings(order?.proceedings ?? []);
	}, [order, refresh]);

	useEffect(() => {
		let sum = 0;
		// orderProceedings.forEach((p: IProceedingDto) => {
		// 	p.services.forEach((s) => {
		// 		sum += s.duration * modelPrice;
		// 	});
		// });

		orderProceedings.forEach((p: IProceedingDto) => {
			sum += p.value;
		});

		setTotalPrice(sum);
	}, [modelPrice, orderProceedings]);

	const handleGenerateDescription = async (services: IOrderServiceDto[]) => {
		let descriptionString = "";
		services.forEach((s: IOrderServiceDto, i: number) => {
			descriptionString +=
				i === services.length - 1
					? `${s.description}`
					: `${s.description} / `;
		});

		await navigator.clipboard.writeText(descriptionString);
		return descriptionString;
	};

	return (
		<>
			{loading && <Loading />}
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
				style={{ marginBottom: "8px" }}
			>
				<Grid item sm={4}></Grid>
				<Grid item>
					<Chip
						color="primary"
						label={`Total: R$ ${
							totalPrice
								? totalPrice.toFixed(2).replace(".", ",")
								: "0,00"
						}`}
						style={{ fontWeight: "bold", fontSize: "1rem" }}
					/>
				</Grid>

				<Grid
					container
					direction="row"
					justifyContent="flex-end"
					alignItems="flex-end"
					spacing={1}
					item
					lg={4}
					md={5}
					sm={6}
				>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							endIcon={<CarCrashIcon />}
							size="small"
							onClick={() => setOpenProceedingsModal(true)}
						>
							Novo Procedimento{" "}
						</Button>
					</Grid>
				</Grid>
			</Grid>

			{orderProceedings.map((p: IProceedingDto) => (
				<React.Fragment key={p.id}>
					<Grid
						style={{ marginTop: "32px" }}
						sm={12}
						container
						justifyContent="space-between"
					>
						<Grid sm={6} item container spacing={1}>
							<Grid item>
								<IconButton
									color="secondary"
									aria-label="delete"
									size="small"
									onClick={async () => {
										await removeOrderProceeding(p.id);
									}}
								>
									<HighlightOffIcon />
								</IconButton>
							</Grid>

							<Grid style={{ marginTop: "4px" }} item>
								<Header text={p.description} />
							</Grid>
						</Grid>

						<Grid
							container
							direction="row"
							justifyContent="flex-end"
							spacing={1}
							item
							lg={6}
							md={6}
							sm={6}
						>
							{" "}
							<Grid item>
								<IconButton
									style={{ zIndex: 1000 }}
									color="inherit"
									aria-label="edit"
									size="small"
									onClick={() => {
										editProceeding(p);
									}}
								>
									<EditIcon />
								</IconButton>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="outlined"
									startIcon={<ContentCopyIcon />}
									onClick={() =>
										handleGenerateDescription(p.services)
									}
								>
									Gerar Descrição
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									endIcon={<SaveAltIcon />}
									size="small"
									onClick={() =>
										importTemplateToProceeding(p)
									}
								>
									Importar Pacote{" "}
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									endIcon={<PlaylistAdd />}
									size="small"
									onClick={() => addServiceToProceeding(p)}
								>
									Adicionar Serviço{" "}
								</Button>
							</Grid>
						</Grid>
					</Grid>

					<TableContainer
						style={{
							marginTop: "8px",
							marginBottom: "8px",
						}}
						component={Paper}
					>
						<Table
							stickyHeader
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell width={"5%"}></TableCell>
									<TableCell width={"60%"}>Serviço</TableCell>
									<TableCell width={"10%"}>Tempo</TableCell>
									<TableCell width={"15%"} align="center">
										Sistema
									</TableCell>
									<TableCell width={"10%"} align="right">
										Valor (R$)
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{p.services.map((s, index) => (
									<TableRow key={s.id}>
										<TableCell>
											<IconButton
												color="default"
												aria-label="delete"
												size="small"
												onClick={() =>
													removeService(s, p)
												}
											>
												<HighlightOffIcon />
											</IconButton>
										</TableCell>
										<TableCell scope="row">
											{s.description}
										</TableCell>
										<TableCell>
											{parseDecimalToHoursAndMinutes(
												s.duration
											)}
										</TableCell>
										<TableCell align="center">
											{getOptionValueById(
												s.system,
												serviceAttributes.systemOptions
											)}
										</TableCell>
										<TableCell align="right">
											R${" "}
											{(s.duration * modelPrice)
												.toFixed(2)
												.toString()
												.replace(".", ",")}
										</TableCell>
									</TableRow>
								))}

								<TableRow
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell />
									<TableCell align="right">
										Total Calculado via Engine{" "}
									</TableCell>
									<TableCell>
										{" "}
										<Typography
											variant="subtitle1"
											color="inherit"
										>
											R${" "}
											{p.services
												.reduce(
													(
														sum: number,
														s: IOrderServiceDto
													) =>
														sum +
														s.duration * modelPrice,
													0
												)
												.toFixed(2)
												.toString()
												.replace(".", ",")}
										</Typography>{" "}
									</TableCell>

									<TableCell align="center">
										<Typography
											variant="h6"
											color="primary"
										>
											Total Atribuído
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography
											variant="h6"
											color="primary"
										>
											R${" "}
											{p.value
												.toFixed(2)
												.toString()
												.replace(".", ",")}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</React.Fragment>
			))}

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}

			{/* FormModal de adição e edição de serviço */}
			<FormModal
				title="Adicionar serviço"
				description="Adicione um serviço ao orçamento"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="orderServiceForm"
			>
				<OrderServiceForm
					services={[]}
					id={id!}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
					modelPrice={modelPrice}
					proceedingId={selectedProceeding?.id ?? ""}
				/>
			</FormModal>

			{/* FormModal de adição e edição de grupo de serviço */}
			<FormModal
				title="Importar Conjunto de Serviços"
				description="Adicione um conjunto de serviços ao orçamento"
				isOpen={openImportTemplateModal}
				handleCancel={handleCancel}
				formToSubmitId="orderServiceGroupForm"
				// proceeding={}
			>
				<OrderImportTemplateForm
					id={id!}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenImportTemplateModal}
					modelPrice={modelPrice}
					proceedingId={selectedProceeding?.id ?? ""}
				/>
			</FormModal>

			{/* FormModal de adição e edição de grupo de serviço */}
			<FormModal
				title={`${
					!!selectedProceeding ? "Adicionar" : "Editar"
				} procedimento`}
				description={`${
					!!selectedProceeding ? "Adicionar" : "Editar"
				} um procedimento no orçamento`}
				isOpen={openProceedingsModal}
				handleCancel={handleCancel}
				formToSubmitId="orderProceedingsForm"
			>
				<OrderProceedingsForm
					id={id!}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenProceedingsModal}
					proceeding={selectedProceeding}
				/>
			</FormModal>
		</>
	);
}
