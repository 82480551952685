import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { getModelById } from "../../../services/api/models";
import { addOrder } from "../../../services/api/orders";
import {
	getDataByPlate,
	getQuotas,
} from "../../../services/apiplacas/apiplacas";
import { IApiPlacasDado, IPlacasData } from "../../../types/ApiPlacas.types";
import { IFormModalProps } from "../../../types/Modal.types";
import { IOrderDto } from "../../../types/Order.types";
import { capitalizeString } from "../../../utils/helpers/parsers";
import { setOrderType } from "../../../utils/setters/orders";
import { IInitialOrderFormInput } from "./OrderForm.form";

export interface IOrderFormProps extends Omit<IFormModalProps, "id"> {}

export default function OrderForm(props: IOrderFormProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const [openWarning, setOpenWarning] = useState<boolean>(false);
	const [quotas, setQuotas] = useState<number>(100);
	const { control, handleSubmit, watch } = useForm<IInitialOrderFormInput>({
		defaultValues: {
			plate: "",
			year: "",
		},
	});
	const [data, setData] = useState<IPlacasData>();
	const [lastPlate, setLastPlate] = useState<string>("");
	const [selectedOption, setSelectedOption] = useState<IApiPlacasDado>();
	const watchSource = watch("source");
	const watchPlate = watch("plate");
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const getRemainingQuotas = useCallback(async () => {
		try {
			const remaining = await getQuotas();
			setQuotas(remaining!.qtdConsultas!);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getRemainingQuotas();
	}, [getRemainingQuotas]);

	const handleClose = () => {
		props.handleCloseModal(false);
	};

	const handleOptionSelection = (value: string) => {
		const option = data?.fipe.dados.filter(
			(d: IApiPlacasDado) => d.codigo_fipe === value
		)[0];
		setSelectedOption(option);
	};

	const onSubmit: SubmitHandler<IInitialOrderFormInput> = async (
		formData: IInitialOrderFormInput
	) => {
		setLoading(true);
		try {
			let payload: IOrderDto = {
				linkedIds: [
					`${selectedOption?.codigo_marca}_${selectedOption?.codigo_modelo}`,
				],
				manufacturer: capitalizeString(data!.MARCA),
				model: data!.MODELO,
				fipeCode: selectedOption!.codigo_fipe,
				type: "draft",
				year: formData.year,
				isActive: true,
				createdDate: new Date(),
				externalId: `${watchSource}-${formData.externalId}`,
				plate: formData.plate,
				services: [],
				parts: [],
				proceedings: [],
				modelAdjustmentFactor: 0,
			};

			const modelData = await getModelById(
				`${selectedOption?.codigo_marca}_${selectedOption?.codigo_modelo}`
			);
			if (!modelData) {
				await addOrder(payload);
				props.refreshParentHandler(!props.refreshParent);
				setLoading(false);
				handleClose();
			}

			if (
				!!modelData?.adjustmentFactor &&
				!!modelData?.dealershipEstimatedPrice &&
				!!modelData?.fipePrice
			) {
				payload.modelAdjustmentFactor = modelData!.adjustmentFactor;
				payload.services = [];
				payload.type = setOrderType(payload);

				await addOrder(payload);
				props.refreshParentHandler(!props.refreshParent);
				setLoading(false);
				handleClose();
			} else {
				await addOrder(payload);
				setLoading(false);
				setOpenWarning(true);
			}

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Orçamento criado com sucesso",
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		}
	};

	const handleWarningClose = () => {
		props.refreshParentHandler(!props.refreshParent);
		setLoading(false);
		handleClose();
		setOpenWarning(false);
	};

	const getDataFromPlate = async () => {
		try {
			setLoading(true);
			setLastPlate(watchPlate);
			const res = await getDataByPlate(watchPlate);
			setData(res);
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{loading && <Loading />}
			{!openWarning && (
				<>
					<form id="orderForm" onSubmit={handleSubmit(onSubmit)}>
						<Grid
							container
							spacing={1}
							justifyContent="space-between"
							alignItems="flex-end"
						>
							<Grid item xs={12} sm={12}>
								<Controller
									name="source"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<RadioGroup
											onChange={onChange}
											row
											value={value}
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
										>
											{" "}
											<FormControlLabel
												value="OM"
												control={
													<Radio color="primary" />
												}
												label="OnMotor"
											/>
											<FormControlLabel
												value="MI"
												control={
													<Radio color="primary" />
												}
												label="Mult Injection"
											/>
										</RadioGroup>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Controller
									name="externalId"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<TextField
											autoFocus
											margin="dense"
											id="externalId"
											label="ID Orçamento"
											type="number"
											InputProps={{
												inputProps: {
													min: 1,
													max: 999999,
													step: 1,
												},
												startAdornment: (
													<InputAdornment position="start">
														{`${
															watchSource ?? ""
														} -`}
													</InputAdornment>
												),
											}}
											fullWidth
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Controller
									name="plate"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<TextField
											autoFocus
											margin="dense"
											id="plate"
											label="Placa do Veículo"
											type="text"
											fullWidth
											onChange={onChange}
											value={value}
											inputProps={{
												maxLength: 7,
												style: {
													textTransform: "uppercase",
												},
											}}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Controller
									name="year"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<TextField
											autoFocus
											margin="dense"
											id="year"
											label="Ano/Modelo"
											type="text"
											fullWidth
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</form>

					<Grid
						justifyContent={
							quotas < 100 ? "space-between" : "flex-end"
						}
						spacing={2}
						alignItems="baseline"
						container
					>
						{quotas < 100 && (
							<Grid item>
								<Typography variant="body2">
									Consultas restantes: {quotas}. Contate o
									desenvolvedor.
								</Typography>
							</Grid>
						)}
						<Grid item>
							<Button
								disabled={
									watchPlate.length !== 7 &&
									watchPlate !== lastPlate
								}
								onClick={getDataFromPlate}
								color="primary"
							>
								Buscar Dados
							</Button>
						</Grid>
					</Grid>

					{data && (
						<div style={{ marginTop: "16px" }}>
							<Typography variant="h6">
								Dados do veículo
							</Typography>

							<Typography variant="body1">
								{`${data.MARCA} ${data.MODELO}  ${
									data.anoModelo
								} ${data.extra.combustivel.toUpperCase()}	`}
							</Typography>

							<Typography variant="h6">
								Selecione entre os modelos disponíveis
							</Typography>

							<RadioGroup
								name="select-fipe-option"
								onChange={(ev) =>
									handleOptionSelection(ev.target.value)
								}
							>
								{data.fipe.dados.map((o: IApiPlacasDado) => {
									return (
										<FormControlLabel
											value={o.codigo_fipe}
											id={o.codigo_fipe}
											key={o.codigo_fipe}
											control={
												<Radio
													size="small"
													color="primary"
												/>
											}
											label={`${o.codigo_fipe} - ${o.texto_modelo}`}
										/>
									);
								})}
							</RadioGroup>
						</div>
					)}
				</>
			)}

			<Dialog open={openWarning} onClose={handleClose}>
				<DialogTitle id="alert-dialog-title">
					Dados do modelo precisam ser preenchidos
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Para que o valor dos serviços seja calculado
						corretamente, é necessário que o cadastro do modelo do
						veículo seja preenchido e os devidos fatores de correção
						sejam calculados e aplicados.
						<br />
						<br />
						Você será redirecionado para a página de edição do
						modelo.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Link
						to={`/modelos/${selectedOption?.codigo_marca}/${selectedOption?.codigo_modelo}`}
						target="_blank"
					>
						<Button
							color="primary"
							onClick={handleWarningClose}
							autoFocus
						>
							Ir para a página de edição
						</Button>
					</Link>
				</DialogActions>
			</Dialog>

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { OrderForm };
