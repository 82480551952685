import { Button, Grid } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Copyright } from "../../components/Copyright";
import { createUser } from "../../services/api/users";

function SignUpForm() {
	let navigate = useNavigate();

	const handleSignUp = useCallback(
		async (event: any) => {
			event.preventDefault();
			const { name, surname, email, password } = event.target.elements;
			const user = {
				name: name.value,
				surname: surname.value,
				email: email.value,
				password: password.value,
			};
			try {
				await createUser(user);
				navigate("/");
			} catch (error: any) {
				console.error(error);
			}
		},
		[navigate]
	);

	return (
		<form onSubmit={handleSignUp}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						name="name"
						required
						variant="standard"
						fullWidth
						id="name"
						label="Nome"
						autoFocus
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="surname"
						label="Sobrenome"
						name="surname"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="email"
						label="Email"
						name="email"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="standard"
						color="primary"
						fullWidth
						name="password"
						label="Senha"
						type="password"
						id="password"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
			</Grid>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				style={{ marginTop: "16px" }}
			>
				CADASTRAR
			</Button>
			<Grid container justifyContent="flex-end">
				<Grid item>
					<br />
					<Link style={{ color: "#ff7221" }} to="/">
						Já possui uma conta? Faça login.
					</Link>
				</Grid>
			</Grid>
		</form>
	);
}

function SignUp() {
	return (
		<div>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: "98vh" }}
			>
				<Grid item xs={3}>
					<h1 style={{ color: "#ff7221" }}>Cadastre-se</h1>
				</Grid>

				<Grid item xs={3}>
					<SignUpForm />
				</Grid>
			</Grid>
			<Copyright />
		</div>
	);
}

export { SignUp };
