import {
	Chip,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
	tableCellClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
	IPartEstimateOnSummaryDto,
	IPartSummarySupplier,
} from "../../types/Parts.types";
import { Header } from "../Typography/Typography";

interface IPartesSummaryProps {
	suppliers: IPartSummarySupplier[];
}

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#303030",
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

export default function PartsSummary(props: IPartesSummaryProps) {
	const [totalBuyCost, setTotalBuyCost] = useState<number>(0);
	const [totalSellingPrice, setTotalSellingPrice] = useState<number>(0);
	const [totalTaxesCost, setTotalTaxesCost] = useState<number>(0);
	const [profitMargin, setProfitMargin] = useState<number>(0);
	const [profitAmount, setProfitAmount] = useState<number>(0);

	useEffect(() => {
		let newTotalBuyCost = 0;
		let newSellingPrice = 0;
		props.suppliers.forEach((s: IPartSummarySupplier) => {
			s.parts.forEach((p: IPartEstimateOnSummaryDto) => {
				newTotalBuyCost = newTotalBuyCost + p.costPrice * p.amount;
				newSellingPrice =
					newSellingPrice +
					p.costPrice * (1 + p.profitFactor / 100) * p.amount;
			});
		});

		setTotalBuyCost(newTotalBuyCost);
		setTotalSellingPrice(newSellingPrice);
		setTotalTaxesCost(newSellingPrice * 0.04);
		setProfitAmount(
			newSellingPrice - (newTotalBuyCost + newSellingPrice * 0.04)
		);
		setProfitMargin(
			(newSellingPrice / (newTotalBuyCost + newSellingPrice * 0.04) - 1) *
				100
		);
	}, [props.suppliers]);

	return (
		<>
			<Grid
				justifyContent="center"
				container
				style={{
					marginTop: "100px",
					marginBottom: "16px",
					minWidth: "1140px",
					maxHeight: "100vh",
					maxWidth: "1140px",
				}}
			>
				<Grid
					container
					spacing={2}
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<Grid item>
						<Chip
							size="small"
							color="info"
							label={`Venda Total: R$ ${totalSellingPrice.toFixed(
								2
							)}`.replace(".", ",")}
						/>
					</Grid>

					<Grid item>
						<Chip
							size="small"
							color="error"
							label={`Custo de Compra: R$ ${totalBuyCost.toFixed(
								2
							)}`.replace(".", ",")}
						/>
					</Grid>
					<Grid item>
						<Chip
							size="small"
							color="error"
							label={`Imposto NF: R$ ${totalTaxesCost.toFixed(
								2
							)}`.replace(".", ",")}
						/>
					</Grid>

					<Grid item>
						<Chip
							size="small"
							color="success"
							label={`Lucro: R$ ${profitAmount.toFixed(
								2
							)}`.replace(".", ",")}
						/>
					</Grid>
					<Grid item>
						<Chip
							size="small"
							color="success"
							label={`Margem de Lucro: ${profitMargin.toFixed(
								2
							)} %`.replace(".", ",")}
						/>
					</Grid>
				</Grid>

				<Grid
					item
					container
					flexDirection="column"
					alignItems="center"
					spacing={2}
					justifyContent="space-between"
					style={{ marginTop: "16px" }}
				>
					{props?.suppliers.map((s: IPartSummarySupplier) => {
						return (
							<>
								<Grid item>
									<Header text={s.name} />
									<TableContainer
										component={Paper}
										style={{
											marginTop: "8px",
											width: "1100px",
										}}
									>
										<Table size="small">
											<TableHead>
												<TableRow>
													<StyledTableCell
														width={"15%"}
														align="left"
													>
														Peça
													</StyledTableCell>
													<StyledTableCell
														width={"10%"}
														align="center"
													>
														Marca
													</StyledTableCell>
													<StyledTableCell
														width={"10%"}
														align="center"
													>
														C. Un.
													</StyledTableCell>
													<StyledTableCell
														width={"5%"}
														align="center"
													>
														Qntd.
													</StyledTableCell>
													<StyledTableCell
														width={"10%"}
														align="center"
													>
														C. Tot.
													</StyledTableCell>
													<StyledTableCell
														width={"10%"}
														align="center"
													>
														V. Tot.
													</StyledTableCell>
													<StyledTableCell
														width={"10%"}
														align="center"
													>
														Imp. Tot.
													</StyledTableCell>
													<StyledTableCell
														width={"5%"}
														align="center"
													>
														C. Real
													</StyledTableCell>
													<StyledTableCell
														width={"5%"}
														align="center"
													>
														Margem
													</StyledTableCell>
													<StyledTableCell
														width={"5%"}
														align="center"
													>
														Lucro
													</StyledTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{s.parts.map((row) => (
													<TableRow
														key={row.description}
														sx={{
															"&:last-child td, &:last-child th":
																{
																	border: 0,
																},
														}}
													>
														{/* Descricao */}
														<TableCell align="left">
															{row.description}
														</TableCell>
														{/* Marca */}
														<TableCell align="center">
															{row.brand}
														</TableCell>
														{/* Custo unitario */}
														<TableCell align="center">
															{`R$ ${row.costPrice
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}`}
														</TableCell>
														{/* Quantidade */}
														<TableCell align="center">
															{row.amount}
														</TableCell>
														{/* Custo Total */}
														<TableCell align="center">
															{`R$ ${(
																row.amount *
																row.costPrice
															)
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}`}
														</TableCell>
														{/* Venda Total */}
														<TableCell align="center">
															{/* Venda Tot. */}
															{`R$${(
																row.costPrice *
																(1 +
																	row.profitFactor /
																		100) *
																row.amount
															)
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}`}
														</TableCell>
														{/* Imposto Estimado Total */}
														<TableCell align="center">
															{`R$${(
																row.costPrice *
																(1 +
																	row.profitFactor /
																		100) *
																row.amount *
																0.04
															)
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}`}
														</TableCell>
														{/* Custo Real */}
														<TableCell align="center">
															{`R$${(
																row.costPrice *
																	(1 +
																		row.profitFactor /
																			100) *
																	row.amount *
																	0.04 +
																row.costPrice *
																	row.amount
															)
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}`}
														</TableCell>
														{/* Margem Lucro Real */}
														<TableCell align="center">
															{`${(
																((row.costPrice *
																	(1 +
																		row.profitFactor /
																			100) *
																	row.amount) /
																	(row.costPrice *
																		(1 +
																			row.profitFactor /
																				100) *
																		row.amount *
																		0.04 +
																		row.costPrice *
																			row.amount) -
																	1) *
																100
															)
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}%`}
														</TableCell>
														{/* Lucro Real */}
														<TableCell align="center">
															{`R$${(
																row.costPrice *
																	(1 +
																		row.profitFactor /
																			100) *
																	row.amount -
																(row.costPrice *
																	(1 +
																		row.profitFactor /
																			100) *
																	row.amount *
																	0.04 +
																	row.costPrice *
																		row.amount)
															)
																.toFixed(2)
																.toString()
																.replace(
																	".",
																	","
																)}`}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</>
						);
					})}
				</Grid>
			</Grid>
		</>
	);
}
