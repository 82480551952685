import { Button, Grid } from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useEffect, useState } from "react";
import FormModal from "../../../../../../components/Modals/FormModal";
import {
	IGroupServiceDto,
	IServiceDto,
} from "../../../../../../types/Services.types";
import ImportTemplateForm from "./ImportTemplateForm";
import ServiceForm from "./ServiceForm";
import { ModelServicesGrid } from "./ServicesGrid";

interface ServiceTabProps {
	id: string;
	services: IServiceDto[];
	refreshParentHandler: any;
	refreshParent: boolean;
	price: number;
	adjustmentFactor: number;
	serviceGroups: IGroupServiceDto[];
}

export default function ServiceTab(props: ServiceTabProps) {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openTemplateImportModal, setOpenTemplateImportModal] =
		useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(props.refreshParent);

	useEffect(() => {
		props.refreshParentHandler(!refresh);
	}, [props, refresh]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
			setOpenTemplateImportModal(false);
		}
	}

	return (
		<>
			{/* FormModal de adição e edição de serviço */}
			<FormModal
				title="Adicionar serviço"
				description="Crie um serviço para o modelo"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="modelServiceForm"
				wide
			>
				<ServiceForm
					id={props.id}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>

			{/* FormModal de importação de template de serviços */}
			<FormModal
				title="Importar agrupamento de serviços"
				description="Importe um agrupamento e todos os serviços contidos nele"
				isOpen={openTemplateImportModal}
				handleCancel={handleCancel}
				formToSubmitId="templateImportForm"
			>
				<ImportTemplateForm
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>

			<Grid
				container
				style={{ marginBottom: "8px" }}
				justifyContent="flex-end"
				spacing={1}
			>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						endIcon={<SaveAltIcon />}
						size="small"
						onClick={() => setOpenTemplateImportModal(true)}
					>
						Importar Template{" "}
					</Button>
				</Grid>

				<Grid item>
					{" "}
					<Button
						variant="contained"
						color="primary"
						endIcon={<PlaylistAdd />}
						size="small"
						onClick={() => setOpenModal(true)}
					>
						Adicionar Serviço{" "}
					</Button>
				</Grid>
			</Grid>

			<ModelServicesGrid
				id={props.id}
				refresh={refresh}
				services={props.services}
				price={props.price}
				adjustmentFactor={props.adjustmentFactor}
				refreshParent={refresh}
				refreshParentHandler={setRefresh}
				serviceGroups={props.serviceGroups}
			/>
		</>
	);
}
