import {
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IFormModalProps } from "../../../../../../../types/Modal.types";
import { IOptions } from "../../../../../../../types/Options.types";
import {
	IGroupServiceServiceDto,
	IServiceDto,
} from "../../../../../../../types/Services.types";
import { serviceAttributes } from "../../../../../../../utils/data/serviceProperties";
import { parseServiceOptions } from "../../../../../../../utils/helpers/parsers";
import {
	IGroupServiceFormInput,
	emptyGroupServiceForm,
} from "./GroupServiceForm.form";

export interface IGroupServiceFormProps extends IFormModalProps {
	services: IServiceDto[];
	groupName: string;
	addServiceHandler: any;
}

export default function GroupServiceForm(props: IGroupServiceFormProps) {
	const { control, handleSubmit, reset, watch } =
		useForm<IGroupServiceFormInput>({
			defaultValues: emptyGroupServiceForm,
		});
	const [service, setService] = useState<string>("");
	const [serviceOptions, setServiceOptions] = useState<IOptions[]>([]);
	const watchSystem = watch("system");

	const handleClose = () => {
		reset(emptyGroupServiceForm);
		props.handleCloseModal(false);
	};

	useEffect(() => {
		const systemServices = props.services?.filter(
			(s) => s.system === watchSystem
		);
		setServiceOptions(parseServiceOptions(systemServices));
	}, [props.services, watchSystem]);

	const onSubmit: SubmitHandler<IGroupServiceFormInput> = async (
		data: IGroupServiceFormInput
	) => {
		try {
			const serviceData = props.services.find((s) => s.id === service);

			const serviceToAdd: IGroupServiceServiceDto = {
				id: service,
				createdDate: new Date(),
				percentage: Number(data.percentage) / 100,
				duration: serviceData!.duration,
				parts: [],
			};

			props.addServiceHandler(serviceToAdd!);

			handleClose();
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<div>
			<form id="groupServiceForm" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<InputLabel id="system">Sistema</InputLabel>

					<Controller
						control={control}
						name="system"
						defaultValue={emptyGroupServiceForm.system}
						render={({ field: { onChange, value } }) => (
							<Select
								labelId="system"
								id="select-system"
								fullWidth
								onChange={onChange}
								value={value}
							>
								{serviceAttributes.systemOptions.map(
									(o: IOptions, i: number) => {
										return (
											<MenuItem key={i} value={o.id}>
												{o.value}
											</MenuItem>
										);
									}
								)}
							</Select>
						)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Controller
						control={control}
						name="service"
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								style={{ marginBottom: "16px" }}
								options={serviceOptions}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								noOptionsText="Nenhum serviço encontrado"
								getOptionLabel={(option: any) => option.value}
								renderInput={(params: any) => (
									<TextField {...params} label="Serviço" />
								)}
								onChange={(e: any, v: any) => {
									setService(!!v ? v.id : "");
								}}
							/>
						)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Controller
						name="percentage"
						control={control}
						defaultValue={emptyGroupServiceForm.percentage}
						render={({ field: { onChange, value } }) => (
							<TextField
								autoFocus
								margin="dense"
								id="percentage"
								label="Percentual de cobrança pelo serviço"
								type="number"
								fullWidth
								InputProps={{
									inputProps: {
										min: 0,
										max: 100,
										step: 0.01,
									},
									endAdornment: {
										...(
											<InputAdornment position="start">
												%
											</InputAdornment>
										),
									},
								}}
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				</FormControl>
			</form>
		</div>
	);
}

export { GroupServiceForm };
