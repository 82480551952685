import { Link, Typography } from "@material-ui/core";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://multinjection.com.br/">
				Mult Injection
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export { Copyright };
