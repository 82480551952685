import { Button, Grid } from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import { useState } from "react";
import HeaderLine from "../../../components/Content/HeaderLine";
import FormModal from "../../../components/Modals/FormModal";
import { ServiceTemplatesGrid } from "./ServiceTemplatesGrid";
import ServiceTemplateForm from "./Templates/ServiceTemplateForm";

export default function ServiceTemplates() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	return (
		<>
			<HeaderLine title="Templates de serviços" />

			{/* FormModal de adição e edição de grupo de serviço */}
			<FormModal
				title="Adicionar Template"
				description="Crie um template de serviços"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="serviceTemplateForm"
			>
				<ServiceTemplateForm
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>

			<Grid
				container
				style={{ marginBottom: "8px" }}
				justifyContent="flex-end"
			>
				<Button
					variant="contained"
					color="primary"
					endIcon={<PlaylistAdd />}
					size="small"
					onClick={() => setOpenModal(true)}
				>
					Adicionar Template{" "}
				</Button>
			</Grid>

			<ServiceTemplatesGrid
				refreshParentHandler={setRefresh}
				refreshParent={refresh}
			/>
		</>
	);
}
