import { ICarModelDto } from "../../types/CarModel.types";
import { IFipeMarca } from "../../types/Fipe.types";
import { IManufacturer } from "../../types/Manufacturer.types";
import { IOptions } from "../../types/Options.types";
import { IGroupServiceDto, IServiceDto } from "../../types/Services.types";
import { sortOptions } from "./sorters";

function parseStringOptions(strings: string[], sorted?: boolean): IOptions[] {
	const newOptions = strings.map((s: string): IOptions => {
		return {
			id: s,
			value: s,
		};
	});

	if (sorted) {
		return sortOptions(newOptions);
	}

	return newOptions;
}

function parseModelOptions(models: ICarModelDto[]): IOptions[] {
	const options = models.map((m: ICarModelDto): IOptions => {
		return {
			id: m.name,
			value: m.name,
		};
	});

	return sortOptions(options);
}

function parseServiceOptions(services: IServiceDto[]): IOptions[] {
	const options = services.map((s: IServiceDto): IOptions => {
		return {
			id: s.id,
			value: s.description,
		};
	});

	return sortOptions(options);
}

function parseTemplateOptions(
	templates: { id: string; description: string }[]
): IOptions[] {
	const options = templates.map(
		(s: { id: string; description: string }): IOptions => {
			return {
				id: s.id,
				value: s.description,
			};
		}
	);

	return sortOptions(options);
}

function parseServiceGroupOptions(groups: IGroupServiceDto[]): IOptions[] {
	const options = groups.map((g: IGroupServiceDto): IOptions => {
		return {
			id: g.name,
			value: g.name,
		};
	});

	return sortOptions(options);
}

// function parseModelAutocompleteOptions(models: ICarModelDto[]): string[] {
// 	const options = models.map((m: ICarModelDto): string => {
// 		return `${m.name} ${m.version ?? ''} ${m.engineCapacity} ${
// 			m.cilinderAmount * m.valvesPerCilinder
// 		}V ${m.fuel} ${m.feeding} ${m.transmission}`;
// 	});

// 	return sortStringItems(options);
// }

function capitalizeString(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

function parseNameToId(s: string[]) {
	let id = "";
	s.forEach((s: string) => {
		if (s !== "") {
			id = id + s.toLowerCase().replace(" ", "") + "-";
		}
	});
	return id.slice(0, -1);
}

function parseDecimalToHoursAndMinutes(d: number) {
	const hours = Math.floor(d);
	const minutes = Math.round((d - hours) * 60);
	return `${hours}h ${minutes}min`;
}

function calculateSellPrice(costValue: number, contributionMargin: number) {
	return costValue / (1 - contributionMargin / 100);
}

function getOptionValueById(id: string, dataSource: IOptions[]) {
	const option = dataSource.find((o: IOptions) => o.id === id);
	if (option) {
		return option.value;
	}
	return "";
}

function parseManufacturerOptions(manufacturers: IManufacturer[]): IOptions[] {
	const options = manufacturers.map((m: IManufacturer): IOptions => {
		return {
			id: m.id,
			value: m.name,
		};
	});

	return sortOptions(options);
}

function parseFipeMarcasOptions(marcas: IFipeMarca[]): IOptions[] {
	const options = marcas.map((m: IFipeMarca): IOptions => {
		return {
			id: m.Value,
			value: m.Label,
		};
	});

	return options;
}

function updateItemInArrayById<T extends { id: string }>(
	array: T[],
	id: string,
	updatedItem: Partial<T>
): T[] {
	// Faz uma cópia do array original para não mutar os dados originais
	const newArray = array.map((item) => {
		if (item.id === id) {
			// Retorna um novo objeto com as propriedades atualizadas
			return { ...item, ...updatedItem };
		}
		// Se não for o item a ser atualizado, retorna o item original
		return item;
	});

	return newArray;
}

function setPartAvailabilityText(availability: string) {
	if (availability === "easy") return "Fácil de encontrar";
	if (availability === "medium") return "Um pouco difícil de encontrar";
	if (availability === "hard") return "Difícil de encontrar";
	if (availability === "") return "Dificuldade não definida";
}

function setPartStatusText(status: string) {
	if (status === "ask") return "Orçar";
	if (status === "requested") return "Solicitada";
	if (status === "delivered") return "Entregue";
	if (status === "") return "Status Indefinido";
}

function setPartAvailabilityColor(availability: string) {
	if (availability === "easy") return "default";
	if (availability === "medium") return "info";
	if (availability === "hard") return "success";
	if (availability === "") return "error";
}

function setPartStatusColor(status: string) {
	if (status === "ask") return "default";
	if (status === "requested") return "info";
	if (status === "delivered") return "success";
	if (status === "") return "error";
}

function setPartAvailabilityIncreaseFactor(availability: string) {
	if (availability === "medium") return 0.2;
	if (availability === "hard") return 0.35;
	return 0;
}

function calculatePartEstimateProfitFactor(
	costPrice: number,
	partAvailability: string
) {
	return (
		(1.2 -
			0.28 * Math.log10(costPrice) +
			setPartAvailabilityIncreaseFactor(partAvailability ?? "")) *
		100
	);
}

export {
	calculatePartEstimateProfitFactor,
	calculateSellPrice,
	capitalizeString,
	getOptionValueById,
	parseDecimalToHoursAndMinutes,
	parseFipeMarcasOptions,
	parseManufacturerOptions,
	parseModelOptions,
	parseNameToId,
	parseServiceGroupOptions,
	parseServiceOptions,
	parseStringOptions,
	parseTemplateOptions,
	setPartAvailabilityColor,
	setPartAvailabilityIncreaseFactor,
	setPartAvailabilityText,
	setPartStatusColor,
	setPartStatusText,
	updateItemInArrayById,
};
