import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";

export interface IActionButtonsProps {
	onDelete?: any;
	onEdit?: any;
}

function ActionButtons(props: IActionButtonsProps) {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			margin: {
				margin: theme.spacing(1),
			},
			button: {
				margin: theme.spacing(1),
			},
		})
	);

	const classes = useStyles();

	return (
		<>
			<div>
				{props.onEdit && (
					<Button
						size="small"
						variant="contained"
						color="primary"
						endIcon={<Visibility />}
						className={classes.button}
						onClick={() => props.onEdit()}
					>
						Visualizar{" "}
					</Button>
				)}
				{props.onDelete && (
					<Button
						style={{ backgroundColor: "#a3081a", color: "white" }}
						variant="contained"
						color="default"
						endIcon={<Delete />}
						className={classes.button}
						onClick={() => props.onDelete()}
						size="small"
					>
						Apagar{" "}
					</Button>
				)}
				{/* <IconButton
					color='primary'
					aria-label='delete'
					className={classes.margin}
				>
					<Edit />
				</IconButton>
				<IconButton
					color='default'
					aria-label='delete'
					className={classes.margin}
				>
					<Delete />
				</IconButton> */}
			</div>
		</>
	);
}

export { ActionButtons };
