import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../modules/auth/Auth";

export function PublicRoute({ children }: React.PropsWithChildren) {
	const { currentUser } = useContext(AuthContext);

	if (currentUser) {
		return <Navigate to="/home" />;
	}

	return <Outlet />;
}
