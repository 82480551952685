const Collections = {
	manufacturers: "manufacturers",
	models: "models",
	users: "users",
	orders: "orders",
	prices: "prices",
	services: "services",
	systems: "systems",
	templates: "templates",
};

export { Collections };
