import { Grid, InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { serverTimestamp } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { createPriceDoc, getCurrentPrice } from "../../../services/api/prices";
import { auth } from "../../../services/config/environments";
import { IKeyNumberEdition } from "../../../types/KeyNumbers.types";
import { IFormModalProps } from "../../../types/Modal.types";
import { calculateSellPrice } from "../../../utils/helpers/parsers";
import { IPriceFormInput } from "./PriceForm.form";

export default function PriceForm(props: IFormModalProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const { control, handleSubmit, reset } = useForm<IPriceFormInput>();
	const [price, setPrice] = useState<IKeyNumberEdition>();
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const getPrice = useCallback(async () => {
		try {
			const currentPrice = await getCurrentPrice();
			setPrice(currentPrice!);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getPrice();
	}, [getPrice]);

	useEffect(() => {
		reset(price);
	}, [price, reset]);

	const handleClose = () => {
		props.handleCloseModal(false);
	};

	const onSubmit: SubmitHandler<IPriceFormInput> = async (
		data: IPriceFormInput
	) => {
		// eslint-disable-next-line no-restricted-globals
		const mustChange = confirm(
			"ATENÇÃO \n\n Esta ação irá alterar o preço base utilizado para todos os cálculos de mão de obra do sistema e não poderá ser desfeita. \n\n É indicado que este dado seja alterado na presença de algum responsável pelo setor financeiro e de estratégia... \n\n Você tem certeza que deseja prosseguir?"
		);

		if (!mustChange) {
			return;
		}
		setLoading(true);

		try {
			const priceEdition: IKeyNumberEdition = {
				author: auth.currentUser!.email as string,
				createdAt: serverTimestamp(),
				costValue: data.costValue,
				contributionMargin: data.contributionMargin,
				sellPrice: calculateSellPrice(
					data.costValue,
					data.contributionMargin
				),
			};

			await createPriceDoc(priceEdition);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Preço base alterado com sucesso",
				severity: "success",
			});
			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="priceForm" onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={1}
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Grid item xs={6} sm={6}>
						<Controller
							name="costValue"
							control={control}
							defaultValue={price?.costValue ?? 0}
							render={({ field: { onChange, value } }) => (
								<TextField
									autoFocus
									margin="dense"
									id="year"
									label="Custo por hora"
									type="number"
									fullWidth
									InputProps={{
										inputProps: {
											min: 0,
											step: "0.01",
										},
										startAdornment: {
											...(
												<InputAdornment position="start">
													R$
												</InputAdornment>
											),
										},
									}}
									onChange={onChange}
									value={value === 0 ? "" : value}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={6} sm={6}>
						<Controller
							name="contributionMargin"
							control={control}
							defaultValue={price?.contributionMargin ?? 0}
							render={({ field: { onChange, value } }) => (
								<TextField
									autoFocus
									margin="dense"
									id="year"
									label="Margem de Contribuição"
									type="number"
									fullWidth
									InputProps={{
										inputProps: {
											min: 0,
											step: "0.01",
										},
										endAdornment: {
											...(
												<InputAdornment position="start">
													%
												</InputAdornment>
											),
										},
									}}
									onChange={onChange}
									value={value === 0 ? "" : value}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { PriceForm };
